import LocalizedStrings from "react-localization";

const LocaleStrings = new LocalizedStrings({
  en: {
    btn_save: "Save",
    search_text: "What are you looking for?",
    required: "Required",
    done: "Done",
    search_not_found: "No results for",
    breadcrumb_home: "Home",
    breadcrumb_search: "Search",
    email: "Email",
    password: "Password",
    conf_password: "Confirm Password",
    signup: "Signup",
    first_name: "First Name",
    last_name: "Last Name",
    nick_name: "Nick Name",
    date_of_birth: "Date of Birth",
    phone_no: "Phone Number",
    confirm_password: "Confirm Password",
    gender: "Gender",
    signin: "Signin",
    login_btn_text: "Login",
    forgot_your_password: "Forgot password?",
    already_account_text: "Already have an account?",
    dont_have_account_text: "Don't have an account?",
    login: "Login",
    signout: "Log Out",
    logout_confirm_title: "Logout",
    logout_confirm_message: "Are you sure you want to logout?",
    invalid_user: "Invalid User!",
    button_logout: "Logout",
    invalid_email_password: "Incorrect email or, password!",
    invalid_user: "Invalid User!",
    login_form_validation_email_required: "Please enter your email address!",
    login_form_validation_password_required: "Please enter your password!",
    form_validation_first_name_required: "Please enter your first name!",
    form_validation_last_name_required: "Please enter your last name!",
    form_validation_phone_no_required: "Please enter your phone no!",
    form_validation_phone_no_valid: "Please enter valid phone no!",
    form_validation_conf_password_required: "Please enter confirm password!",
    form_validation_password_not_match:
      "Password and confirm password does not match!",
    email_password: "Enter your email & password to login",
    create_a_new_account: "Create an account",
    my_account: "My Account",
    categories: "Categories",
    subcategories: "Sub Category",
    show_all: "Show All",
    product_desc: "Product Description",
    product_overview: "Overview",
    variant: "Variant",
    shipping: "Shipping",
    product_spec: "Specification",

    variant_not_selected: "Please select a variant to replace",
    common_fail_message: "Something went wrong!",
    signup_error_already_registered: "email already registered",
    signup_success: "Account created successfully!",
    login_success: "Successfully! logged in",
    connect_social_media_text: "Connect with social media",
    signup_error_phone_already_registered: "Phoneno already registered",
    signup_heading: "Lets get started by creating a new account",

    // Forgot Password Modal
    forgot_password: "Forgot Password",
    password_reset_link_text:
      "Please enter the registered email and will send you a password reset link",
    send_reset_link: "Send Reset Link",
    close: "Close",
    forgot_password_success: "Password reset link sent successfully",
    reset_btn: "Reset",
    reset_password_heading: "Reset Password",
    reset_confirm_code: "Confirm Code",
    reset_password_success: "Password updated successfully",
    forgot_password_form_error_messages: "email id not registered with Shopez",

    // Product Details
    products: "Products",
    product_specification: "Product Specification",
    ask_question_placeholder: "Type your question",
    ask_btn: "Submit",
    ask_question_success: "Question Added",
    ask_question_form_error: "Question Required",
    question_and_answer: "Questions & Answers",
    question_and_answer_subheading: "Want to Know More About the Product?",
    ask_question_btn: "Ask a Question",
    view_all_qa_link: "View All Questions & Answers",
    all_question_text: "Question(s)",
    view_all_question_link: "Load more questions",
    no_question_found: "No question found",
    reviews: "Reviews",
    view_all_review_link: "Load more reviews",
    all_review_text: "All Reviews",
    view_all_details: "View All Details",
    reviews_txt: "Reviews",
    review_txt: "Review",
    review_all_text: "/5.0",
    review_recommeded_text: "Recommended",
    review_search_text: "Search",
    review_sortby_low_to_high: "Negative First",
    review_sortby_high_to_low: "Positive First",
    review_sortby_newest_first: "Newest First",
    sold_txt: "sold",
    wishlist_txt: "Add to Wishlist",
    wishlisted_txt: "Wishlisted",
    watchlist_txt: "Watchlist",
    watching_txt: "Watching",
    watchlist_remove_success: "Item removed from watchlist",
    watchlist_add_success: "Item added to watchlist",
    watchlist_delete_item_success: "Item deleted from Watchlist",
    product_quantity_text: "Quantity",
    wishlist_clear_success: "Item cleared from Wishlist",
    wishlist_delete_success: "Wishlist deleted",
    wishlist_delete_item_success: "Item deleted from Wishlist",

    wishlist_delete_title: "Delete Wishlist",
    wishlist_delete_msg: "Are you sure you want to delete this wishlist?",
    wishlist_clear_title: "Clear Wishlist Item",
    wishlist_clear_msg: "Are you sure you want to clear items?",
    button_yes: "Yes",
    button_no: "No",
    button_ok: "Ok",
    button_delete: "Delete",
    button_edit: "Edit",
    button_edit_profile: "Edit Profile",
    item: "Item",
    items: "Items",
    wishlist_share_success: "Wishlist shared",
    wishlist_item_delete_option: "Delete Items",
    wishlist_clear_option: "Clear Wishlist",
    wishlist_delete_option: "Delete Wishlist",
    wishlist_share_option: "Share Wishlist",
    wishlist_no_product: "There's no Product(s) added yet",
    wishlist_remove_success: "Item removed from Wishlist",
    wishlist_add_success: "Item added to Wishlist",
    wishlist_slider_title: "Add to Wishlist",
    create_new_wishlist: "Create New Wishlist",
    create_new_wishlist_name: "Wishlist Name",
    create_new_wishlist_privacy: "Privacy",
    create_new_wishlist_public: "Public",
    create_new_wishlist_private: "Private",
    create_new_wishlist_btn: "Save Wishlist",
    create_new_wishlist_name_required: "Name required",
    wishlist_name_limit_error: "30 characters only",
    category: "Category",
    sub_category: "Sub Category",
    condition: "Condition",
    make_private: "Make Private",
    wishlist_private_success: "Successfully convert to private",

    share_txt: "share",
    shop_now: "Shop now",
    similar_products: "Similar Products",
    similar_products_not_found: "No Similar Products Found",
    view_all_similar_products: "View All Similar Products",
    you_may_like_products: "Products You May Like",
    rating: "Rating",
    and: "and",
    star: "Star",
    share_heading: "Share",

    starts_in: "Starts in",
    ends_in: "Ends in",
    bid_not_started: "Bid not Started",
    bid_over: "Ended",
    expired: "Expired",
    out_of_stock: "Out of Stock",
    notify_available_text: "Notify when available",
    cancelled: "Cancelled",
    not_deliverable: "Not Deliverable",
    temporarily_unavailable: "Temporarily unavailable",

    place_a_bid: "Place a Bid",
    cancel: "Cancel",
    make_offer: "Make your offer",
    place_bid: "Place Bid",
    bid_private_text: "Keep my name anonymous",
    bid_commited_text:
      "By placing a bid you are committed to purchase the product if you win.",
    place_a_bid_price_error: "Price Required",
    place_a_bid_min_price_error:
      "Price should be equal/greater than highest bid price",
    highest_bid: "Highest Bid",
    your_last_bid: "Your Last Bid",
    starting_bid: "Starting Bid",
    bid: "Bids",
    nobid: "Bid",
    place_a_bid_commited_error: "Required",
    place_re_bid: "Re Bid",

    view_comments_btn: "Comment",
    comment: "Comments",
    likes_text: "Likes",
    like_text: "Like",

    delivery: "Delivery",
    deliverto: "Deliver To",
    deliveryby: "Delivery By",
    soldby: "Sold By",
    follower: "Followers",
    following: "Followings",
    follow_btn: "Follow",
    unfollow_btn: "Unfollow",
    send_message_btn: "Send Message",
    return_policy: "Return Policy",
    replacement_policy: "Replacement Policy",

    //Add to Cart
    add_to_cart_success: "Item added to Cart",
    choose_variant: "Choose Variants",
    selected_variant: "Selected Variant",
    add_to_cart_btn: "Add to Cart",
    slider_title: "Negotiate",
    negotiate_btn: "Negotiate & Buy",
    quantity_not_available: "Quantity not available",
    negotiate_view_btn: "View Negotiation",
    order_placed_success: "Order Successfully Placed",
    step1: "Cart",
    step2: "Address",
    step3: "Payment",

    //Negotiation
    negotiation_ini_success_message: "Negotiation initiatiated",
    negotiation_quantity_text: "Quantity",
    negotiation_pending_text: "Pending Confirmation",
    negotiation_rejected_text: "Offer Rejected",
    negotiation_accepted_text: "Offer Accepted",
    negotiation_closed_text: "Offer Closed",
    negotiation_enter_price_text: "Enter your price ",
    negotiation_attempt_left_text: " attempt left",
    negotiation_proceed_purchase_btn: "Proceed to Purchase",
    negotiation_status: "Status",
    negotiated_won_variant_error: "Negotiated won variant already in the cart",
    shipping_and_taxes_extra: "Shipping and Taxes extra",
    negotiation_cancel: "Cancel",

    //Negotiation List
    negotiation_text: "Negotiation",
    negotiation_not_found: "No Negotiation(s)",
    negotiation_active: "Active",
    negotiation_won: "Won",
    negotiation_lost: "Lost",
    negotiation_won_text: "Negotiation Won",

    negotiation_won_title: "No negotiation(s) Won",
    negotiation_won_desc: "You haven't won any negotiation(s)",
    negotiation_lost_title: "No negotiation(s) Lost",
    negotiation_lost_desc: "You haven't lost any negotiation(s)",
    negotiation_active_title: "No negotiation(s)",
    negotiation_active_desc: "There's no product negotiated yet",
    negotiation_note: "You will get notified once seller responded.",

    //Watchlist
    watchlist_tab_watching: "Watching",
    watchlist_tab_mybids: "My Bids",
    mybids_not_found: "No bid(s)",

    //Mention
    mention_slider_title: "Mentions",
    mention_no_comment: "There's no Comment(s) yet",
    mention_add_success: "comment posted successfully",
    mention_update_success: "comment updated successfully",
    type_your_message: "Type your message",

    //Activity
    activity: "Activity",
    shop: "Shop",
    activity_you: "You",
    buying_history: "Buying History",
    activity_followers: "Followers",
    acitvity_recently_viewed: "Recently Viewed",
    acitvity_watchlist: "Watchlist",
    acitvity_wishlist: "Wishlist",
    recently_viewed_not_found: "No Recently Viewed(s)",

    buying_history_purchases: "Purchases",
    buying_history_purchase_made: "Purchases Made",
    buying_history_no_purchases: "No purchase(s) made yet",
    buying_history_last_purchases: "Last purchase was made ",
    buying_history_bids: "bids",
    buying_history_bids_placed: "Bids Placed",
    buying_history_no_bids: "No bids(s) placed yet",
    buying_history_last_bid: "Last bid placed ",
    buying_history_offers: "Offers",
    buying_history_offers_negotiation: "Negotiation Offer",
    buying_history_no_negotiation: "No negotiations made yet",
    buying_history_last_offer: "Last offer was made",
    buying_ago: " ago",

    watcher_list: "Watcher List",

    view_my_orders: "View My Orders",
    view_my_bids: "View My Bids",
    view_negotiation: "View Negotiations",
    public_wishlist_feed: "created public wishlist",
    public_mention_feed: "mention",
    new_product_feed: "created new product",
    coupon_feed: "created an upcoming coupon",
    discount_feed: "created an upcoming discount",

    //My Bids
    no_bids_won_title: "No bid(s) Won",
    no_bids_won_desc: "You haven't won any bid(s)",
    no_bids_lost_title: "No bid(s) Lost",
    no_bids_lost_desc: "You haven't lost any bid(s)",
    no_bids_active_title: "No bid(s) Active",
    no_bids_active_desc: "You have no active bid(s)",

    no_bids_title: "No bid(s)",
    no_bids_desc: "You are not bidding on any products(s)",
    no_watching_title: "No Products(s)",
    no_watching_desc: "You are not watching any bid(s)",
    no_wishlist_title: "No Shared Wishlist(s)",
    no_wishlist_desc: "You don't have any shared wishlist yet",

    //My Followers
    my_followers: "My Followers",
    my_followers_no_data_title: "No Follower(s)",
    my_followers_no_data_desc: "You don't have any followers yet",

    shared_wishlist_tab_key: "shared",
    following_tab_key: "following",
    shared_wishlist_tab: "Shared Wishlist",
    following_tab: "Following",

    shared_wishlist_tab: "Shared Wishlist",
    following_tab: "Following",

    shared_no_data_title: "No Shared Wishlist(s)",
    shared_no_data_desc: "The user haven't shared any wishlist",
    you_shared_no_data_desc: "You don't have any shared wishlist yet",

    following_no_data_title: "No Following(s)",
    following_no_data_desc: "The user is not following any user",
    you_following_no_data_desc: "You are not following any user",

    //Feed
    based_interest: "Based on Your Interest",
    followed: "followed",

    //Sidemenu
    menu_home: "Home",
    menu_profile: "Profile Settings",
    menu_language: "Language",
    menu_currency: "Currency",
    menu_categories: "All Categories",
    menu_order: "My Orders",
    menu_bidplace: "Bid Placed",
    menu_negotiation: "Negotiations",
    menu_coupon: "My Coupons",
    menu_invitation: "My Invitations",
    menu_suggestion: "Suggestions",
    menu_faq: "FAQ & Support",
    menu_legal: "Legal Docs",
    menu_signin: "Signin",
    menu_signout: "Signout",

    //Messages
    message_title: "Messages",
    send_message: "Send Message",
    type_message: "Type your message",
    no_message_header: "No messages yet",
    no_message_subheader: "Stay tuned! Messages will show up here.",
    message_tab_product: "Products",
    message_tab_order: "Orders",

    no_review_header: "No reviews yet",
    no_review_found: "No reviews found",

    //Seller Profile
    seller_profile: "Seller Profile",
    topcategories: "Top Categories",
    top_selling_item: "Top Selling Items",
    rating_from: "from",
    location: "Location",
    member_shopez: "Member of Shopez Since",
    item_as_described: "Item as Described",
    communication: "Communication",
    shipping_time: "Shipping Time",
    map: "Map",
    select_language: "Select Language",
    topsel_low_to_high: "Top Selling low to high",
    topsel_high_to_low: "Top Selling high to low",
    away_timing: "Away timing",

    anonymous: "Anonymous",

    //Cart
    cart_address: "Address",
    cart_text: "Shopping Cart",
    cart_breadcrumb: "Cart",
    cart_applied_coupons: "Applied Coupons",
    cart_get_coupon: "Get Coupon",
    cart_proceed_checkout: "Proceed to Checkout",
    cart_continue_shopping: " < Continue Shopping",
    cart_continue: "Continue",
    cart_order_summery: "Order Summary",
    cart_discount: "Discount",
    cart_coupon_code: "Coupon Code",
    cart_shipping_fee: "Shipping Fee",
    cart_tax_charges: "Tax & Charges",
    cart_total: "Total",
    cart_shipping_address: "Shipping Address",
    cart_payment: "Payment",
    cart_payment_method: "Payment Method",
    cart_payment_card: "Pay with card",
    cart_payment_cash: "Pay with cash",
    cart_payment_cash_not_available: "Pay with cash is not available",
    cart_address_change_btn: "Change",
    cart_new_address: "New Address",
    cart_item_selected: "Items Selected",
    cart_sku: "SKU",
    cart_remove: "Remove",
    cart_product_price: "Product Price",
    cart_product_discount: "Discount",
    cart_coupon_discount: "Coupon Discount",
    cart_available_coupons: "Available Coupons",
    cart_flat_discount: "Flat",
    cart_coupon_apply: "Apply",
    cart_coupon_expired: "Expired",
    cart_coupon_min_error:
      "Minimum cart value should be equal or greater than ",
    cart_item_not_selected: "No item is selected",
    cart_coupon_applied_success: "coupon code applied successfully",
    cart_coupon_error1: "Coupon already used!",
    cart_coupon_error2: "Cannot be applied on existing items!",
    cart_coupon_error3: "Coupon quota exhausted!",
    cart_coupon_error4: "Coupon cannot be applied!",
    cart_coupon_copied: "Coupon copied",
    cart_coupon_not_available: "Coupon is not available!",
    cart_coupon_has_expired: "Coupon has Expired!",
    cart_coupon_not_exist: "Coupon code not exist!",
    cart_billing_address: "Billing Address",
    cart_review_items: "Review Items",
    same_as_shipping: "Same as Shipping address",
    cart_select_shipping_address: "SELECT SHIPPING ADDRESS",
    contact_name: "Contact Name",
    country: "Country",
    address: "Address",
    city: "City",
    zip: "Zip",
    state: "State",
    review_order: "Review Your Order",
    make_default_title: "Make Default",
    make_default_msg: "Do you want to make this as a default address",
    button_once: "No, use once",
    button_add_new_address: "Add New Address",
    button_edit_address: "Edit Address",
    button_update_address: "Update",
    button_save_address: "Save Address",
    empty_cart_title: "Your Cart is empty!",
    empty_cart_desc: "Add items to it now",
    cart_coupon_applied: "Applied Coupon",
    cart_place_order: "Place Order",
    cart_no_address: "No Address",
    cart_add_address: "Please add a address",
    available_address: "Address",
    buildingno: "Building No",
    zoneno: "Zone Number",
    streetno: "Street Number",
    unitno: "Unit No",
    poboxno: "P.O Box",
    order_not_proceed:
      "Some of your item(s) either out of stock or temporarily unavailable",

    //Report Abuse
    report_as_abusive: "Report as abusive",
    report_title: "Report",
    report_button: "Report",
    report_confirm_product_title: "Report Product",
    report_confirm_product_message:
      "Are you sure you want to report about this product?",
    report_success: "You have successfully reported the comment as abusive",
    report_confirm_comment_title: "Report Comment",
    report_confirm_comment_message:
      "Are you sure you want to report about this comment?",

    report_confirm_review_title: "Report Review",
    report_confirm_review_message:
      "Are you sure you want to report about this review?",

    report_confirm_question_title: "Report Question",
    report_confirm_question_message:
      "Are you sure you want to report about this question?",

    report_confirm_answer_title: "Report Answer",
    report_confirm_answer_message:
      "Are you sure you want to report about this answer?",

    report_confirm_consumer_title: "Report Consumer",
    report_confirm_consumer_message:
      "Are you sure you want to report about this user?",

    //Coupon
    coupon_copy: "Copy",
    my_coupons: "My Coupons",
    product_coupons: "Coupons",

    //My Order
    my_orders: "My Orders",
    order_id: "Order ID",
    order_details_pay_now: "Pay Now",
    order_details_btn: "Details",
    order_details: "Order Details",
    order_delivered: "Delivered",
    order_dispatched: "Dispatched",
    order_Placed: "Placed",
    order_cancelled: "Cancelled",
    initiate_refund: "Initiate Refund",
    initiate_pickup: "Pickup Initiated",
    return_received: "Return Received",
    return_picked: "Return Picked",
    return_requested: "Return requested",
    replacement_requested: "Replacement requested",
    refund_initiated: "Refund Initiated",
    refunded: "Refund",
    payment_pending: "Pending",
    refund_rejected: "Refund Rejected",
    reshipdelivered: "Order Delivered",
    reship: "Replace reshippped",
    in_transit: "In Transit",
    seller: "Seller",
    sku: "SKU",
    quantity: "QTY",
    inclusive_text: "Inclusive all taxes and charges.",
    qar_total_text: "You'll be charged in QAR and total = ",
    order_status: "Order Status",
    contact_no: "Contact No : ",
    payment_method: "Payment",
    rate_delivaery: "Rate Delivery",
    btn_return: "Return",
    btn_cancel: "Cancel",
    btn_return_replace: "Return/Replace",
    btn_replace: "Replace",
    btn_reorder: "Reorder",
    confirm_return_replace: "Are you sure do you want to return/replace?",
    confirm_refund: "Are you sure do you want to return?",
    confirm_replace: "Are you sure do you want to replace?",
    confirm_cancel: "Are you sure do you want to cancel?",
    cancel_reason: "Cancel Reason:",
    refund_cancel_reason: "Refund Reject Reason:",
    order_cancel: "Order Cancelled",
    cancel_note: "You will get notified once status updated",
    reject_reason: "Refund Reject Reason:",
    return_reason: "Return/Replace Reason:",

    //Refund Retrun/Replace
    return_title: "Return Item",
    return_heading_text: "What would you like to do with the product?",
    replace_product: "Replace Product",
    return_product: "Return Product",
    confirm_request: "Confirm Request",
    confirm_and_pay: "Confirm & Pay",
    select_reason_text: "Select reason to replace",
    applicable_text: "Check all applicable reasons",
    explain_placeholder: "Explain the reasons",
    reason_item_match: "Item was damaged when received",
    reason_item_color: "Wrong colour/model received",
    reason_item_damage: "Item was damaged when received",
    reason_other: "Some other reason? Please write your reason",
    return_status_error: "Status Required",
    return_reason_error: "Reason Required",
    select_reason_error: "Select any reason",
    cancel_success: "Product Cancelled Succefully",
    return_success: "Return request done successfully",
    replace_success: "Replace request done successfully",
    cancel_request: "Cancel Request",
    cancel_btn: "Cancel",
    no_order_title: "No Order(s)",
    no_order_desc: "You have not placed any order yet",
    order_success: "Order placed successfully",

    rate_product: "Rate Product",
    rate_product_rating: "Rate Product (rating low to high)",
    rate_product_desc:
      "Please rate the below criteria as per your experience (rating low to hight)",
    rate_product_point1: "1. How accurate was the product description?",
    rate_product_point2:
      "2. How satisfied were you with the seller's Communication?",
    rate_product_point3: "3. How quickly did the seller ship the item?",
    rate_product_point4: "4. You can leave your feedabck",
    rate_product_attach: "Attach files",
    rate_product_anonymus: "Submit feedback as anonymous",
    rate_product_error: "Please rate the product",
    submit_rating: "Submit Rating",
    review_submit_success: "Review submitted successfully",

    cancel_refund: "Cancel refund initiated",
    cancel_refunded: "Cancel refunded",
    cancel_refundrejected: "Refund Rejected",
    cancel_return: "Cancel Return",
    refund_processing: "Refund Processing",

    //Category View
    no_product_title: "No Products(s)",
    no_product_desc: "There's no Product(s) added yet",

    // Product View
    product_title: "Products",
    item_text: "item",
    items_text: "items",
    sortby: "Sort by",
    sortby_low_to_high: "Price Low to High",
    sortby_high_to_low: "Price High to Low",
    sortby_popularity: "Popularity",
    sortby_newest_first: "Newest First",
    sortby_bestmatch: "Best Match",
    sortby_endingsoonest: "Ending Soonest",
    price_title: "Price",
    filter_text: "Filter",
    clear_all: "Clear All",
    no_product_found: "No Product(s) found",
    all_category: "All Category",

    /**** Profile Settings ****/
    profile_settings_list_text1: "Profile Information",
    profile_settings_list_subtext1: "Manage your account",

    profile_settings_list_text2: "Address",
    profile_settings_list_subtext2: "Manage your address",

    profile_settings_list_text3: "Notification",
    profile_settings_list_subtext3: "Turned on",

    profile_settings_list_text4: "Account Settings",
    profile_settings_list_subtext4: "Manage payment accounts",

    profile_settings_list_text5: "My Followers",
    profile_settings_list_subtext5: "See who follows you",

    update_account: "Update Account",
    profile_settings_actsettings_terms_text:
      "All your earnings are collected on shopez and will be sent to you as per 7 days cycle.Please add your bank account details where you whould like to receive the payments",

    beneficiary_name: "Beneficiary Name",
    beneficiary_full_name: "Beneficiary full name",

    bank_name: "Bank Name",
    beneficiary_bank_name: "Beneficiary bank name",

    act_settings_act_number_lbl: "Account Number/IBAN",
    act_settings_act_number_placeholder: "Beneficiary account no./IBAN",

    act_settings_swift_code_lbl: "Swift code/Bic code/sort code",
    act_settings_swift_code_placeholder: "Swift code/Bic code/sort code",

    select_country: "Select Country",

    act_settings_contact_number_lbl: "Contact Number",
    act_settings_contact_number_placeholder:
      "Beneficiary contact no.(optional)",

    // My Followers
    text_you: "you",
    btn_follow: "Follow",
    btn_unfollow: "Unfollow",

    profile_settings_myfollowers_sheardwhishlist: "Shared Wishlist",
    profile_settings_myfollowers_Following: "Following",

    no_shared_wishlist: "No Shared wishlist(s)",
    user_havenot_shared_wishlist: "The user haven't shared any wishlist",

    no_following: "No Following(s)",
    user_not_following_user: "The user is not following any user",

    items: "Items",

    // Profile Information
    update_profile: "Update Profile",
    get_verified: "Get verified",
    no_rating: "No Rating",
    your_profile_is_unverified: "Your profile is Unverified",
    profile_updated_successfully: "Profile information updated successfully",
    add_phone_number: "Add phone number",
    update_password: "Update Password",
    update_phone_number: "Update Phone Number",
    verify_phone_number: "Verify",
    phone_updated_successfully: "Phone number updated successfully",
    otp_verified_successfully: "OTP verified successfully",
    otp_not_verified: "OTP not verified",
    mobile_not_verified: "You have not verified mobile number yet.",

    update_email: "Update Email",
    email_updated_successfully: "Email updated successfully",
    email_not_verified: "You have not verified email yet.",

    // Store Information
    update_store_info: "Update store info",
    contact_person_label: "Contact Person's",
    contact_person_placeholder: "Contact person's name",
    contact_number_label: "Contact number",
    another_number_label: "Another number",
    add_another_number_btn: "Add another number",
    select_away_time: "Select away time",
    store_info_start_date_label: "Start date",
    store_info_end_date_label: "End date",
    set_automatic_response_text: "Set Automatic response",
    set_automatic_response_subtext:
      "(Automatic response is for anyone who contacts through messages during time away)",
    set_automatic_response_placeholder: "hey hello how's your day going hey",
    store_updated_successfully: "Store information updated successfully",
    delete_contact_confirm: "Delete Contact Number",
    delete_contact_number_confirm_text:
      "Are you sure you wish to delete it? This action cannot be undone",
    contact_deleted_successfully: "Contact Deleted successfully",
    plus_add_another_number: "+ Add another number",
    location_header: "Location",
    store_location_text: "Store location",
    search_places: "Search Places ...",
    search_address: "Search Address",
    search_your_location: "Search your location",
    latitude: "Latitude",
    longitude: "Longitude",
    zoom: "Zoom",
    address: "Address",
    current_password: "Current Password",
    confirm_password: "Confirm Password",
    security_code: "Current Password",
    phone_number: "Phone Number",
    update_phone_number_text: "Please enter the number you wish to update",
    current_number: "Current Number",
    send_code: "Send Code",
    send_link: "Send Link",
    phone_number_verfication_text:
      "Please enter the verification code that we have sent to your phone number.",
    verification_code_mismatch: "Verification code does not match",
    store_location_verfication_text: "Please enter store location",

    send_code_success: "OTP sent to given Number",
    otp_has_been_sent_success: "OTP has been sent",

    // Store Verification
    submit_verification: "Submit Verification",
    attach_files: "Attach files",
    attach_document: "Attach document",
    commercial_registration_document: "Commercial registration document",
    agreement_template_duly_filled_and_signed:
      "Agreement template duly filled and signed",
    store_verification_form_header:
      "If you want to become a trusted vendor and earn more custom, get your account verified on shopez!",
    store_verification_form_subheader:
      "Upload the documents and earn a verified mark",
    on_your_profile: "on your profile",
    vendor_verified_text: "You are an approved and verified User",
    download_agreement_template: "Download agreement template",
    valid_id_proof: "Valid ID proof",
    upload_only_doc_and_pdf_file_error_text:
      "Not a valid file! upload only doc and pdf file",
    attachfile_to_update_error_text: "Attach files to update",

    // App Suggestions
    app_suggestions_header_text: "Select your suggestions",

    app_suggestions_lbl_email_address: "Email Address",
    app_suggestions_plshldr_email_address: "Email Address",

    app_suggestions_lbl_suggestion_desc: "Suggestion Description",
    app_suggestions_plshldr_suggestion_desc: "Add Description",

    app_suggestions_footer_text:
      "Your suggestion will be emailed to Shopez and necessary actions will be taken",
    send_suggestion: "Send Suggestion",

    file_attached: "file attached",
    files_attached: "files attached",

    bug: "Bug",
    app_feature: "Feature",
    app_suggestions_submit_success: "Your suggestions submitted successfully",

    // FAQ & Support
    raise_a_ticket: "Raise a Ticket",
    faq_footer_head_text: "Need more help? Contact Us",
    call_us: "Call Us:",
    write_to_us: "Write to us:",
    raise_tacket_sub_header_text:
      "If you haven't got the answer to your problems, please write to us.",
    write_your_problems: "Write your problems",
    write_your_questions: "Write your question?",
    search_text_faq_support: "Search our FAQs",

    faq_support_submit_success: "Your concern was submitted successfully",

    // Legal Docs
    consumer_legal_docs_list_text1: "Terms & Conditions",
    consumer_legal_docs_list_subtext1: "View our terms & conditions",

    consumer_legal_docs_list_text2: "Privacy Policy",
    consumer_legal_docs_list_subtext2: "View our privacy policy",

    consumer_legal_docs_list_text3: "Sales Policy",
    consumer_legal_docs_list_subtext3:
      "View our return / exchange / bidding policy",

    consumer_legal_docs_list_text4: "About us",
    consumer_legal_docs_list_subtext4: "View our story",

    male: "Male",
    female: "Female",
    rather_not_say: "Rather Not Say",
    password_updated_success: "Password Changed Successfully",

    //Notification Settings
    nsetting_auction_updates: "Notify me when item send auction updates",
    nsetting_aubid_or_win: "Notify when I am outbid or win and item",
    nsetting_interested_updated:
      "When an update is made in the item I am interested in",
    nsetting_order_update:
      "Order updates - when an update is made to an order I have made",
    nsetting_receive_message: "When I receive message from Shopez member",
    nsetting_top_deal: "Shopez top deal of the day",
    nsetting_followed: "Someone follows me",
    nsetting_invited: "Someone invited by me joins",
    nsetting_mentioned: "Someone mentions me on the product",
    nsetting_violation: "Violation message recived from admin",

    // Payment Cancel/ Fail / Success
    pay_pending_title: "Payment Pending",
    pay_pending_desc: "Transaction Pending",
    pay_pending_bank_title: "Payment Pending",
    pay_pending_bank_desc: "Transaction pending confirmation from bank",
    pay_fail_title: "Payment Failed",
    pay_fail_desc: "Transaction failed",
    pay_cancelled_title: "Payment Cancelled",
    pay_cancelled_desc: "Transaction Cancelled",
    pay_success: "Your Transaction is successfull",

    address_delete_title: "Delete Address",
    address_delete_msg: "Are you sure want to delete this address?",
    address_add_success: "Address added",
    address_update_success: "Address updated",

    drop_or_select: "Drop or select",
    phone_exist: "Phone number already exist",

    // Notification
    notifications: "Notifications",
    read_all: "Read All",
    update: "Update",
    mark_all_messages_as_read: "Mark all messages as read",
    no_notification_header: "No Notification(s)",
    no_notification_subheader: "No notification received yet",

    //Change Currency
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    //Account
    country_code: "Select Country",
    check_digits: "Check Digits",
    bank_code: "SWIFT Code/BIC Code/Sort Code",
    account_number: "Benificiary Account No./IBAN",
    iban: "IBAN",
    add_account: "Add Account",
    invalid_length: "Invalid",
    account_add_success: "Account added successfully",
    account_update_success: "Account updated successfully",
    benificiary_name: "Benificiary Name",
    benificiary_bankname: "Benificiary Bank Name",
    benificiary_contactno: "Benificiary Contact No.",

    // My Invitation
    my_invitation: "My Invitation",
    invite_message: "Invite Message",
    email_invitation:
      "Invite your friends by adding emails. You can add multiple emails seperated by a comma(,) and send invites all at once.",
    send_invitation: "Send Invitation",
    invitation_submit_success: "Invitation sent seccessfully",

    readmore: "ReadMore",
    readless: "ReadLess",

    signup_as_vendor: "Signup as Vendor",
    vendor_mode: "Vendor mode",

    verifyemail_success: "email verified successfully",
    verifyemail_already: "email already verified",

    //Blocker
    skip: "Skip",
    verification_header_text1: "Verify email",
    verification_subheader_text1: "Verify your email address",
    verification_header_text2: "Verify",
    verification_subheader_text2: "Verify your phone number",
    verification_header_text3: "Upload Documents",
    verification_subheader_text3:
      "Before you can start using Shopez, Please upload the following:",

    verification_email_note_text1:
      "In order for us to activate your account and let you login, we need to verify that the email address. We have sent an email to (",
    verification_email_note_text2:
      ") which contains a link you need to click on...",

    verification_email_note_text3:
      "You have entered the wrong email id? You can change the email now",

    verification_email_note_text4:
      "Your new email has been successfully updated! Please relogin to verify your new email.",

    verification_email_resend_btn: "Haven't received the email? Resend",

    verification_phone_change_number: "Change Number?",
    verification_phone_change_number_field_name: "Change Number",
    verification_phone_change_number_field_placeholder: "Enter your number",

    enter_wrong_emailid: "You have entered the wrong email id",
    email_varified_successfully: "email verified successfully",
    phone_varified_successfully: "Phone number verified successfully",
    resent_email_alert_text:
      "email has been sent to your register email address !",
    email_exist_alert_text: "email already exist ! Enter another",

    resend: "Resend",
    email_address: "Email",
    legal_docs_accept_tnc_text: "I accept the Terms and Conditions",
    terms_error: "Please select terms & conditions",
    copy_link: "Copy Link",
    copied: "Copied",
    refunded: "Refunded",
    email_terms_text: "Email me a copy of Terms & Conditions",

    outofstock_notify_success: "You will get notified",
    outofstock_already_opted: "You will get notified",

    loader_message: "Please Wait",
    banned_item_header: "Not Found",
    banned_item_subheader:
      "Sorry! the item you are looking for is temporarily unavailable",
    banned_profile_subheader:
      "Sorry! the profile you are looking for is temporarily unavailable",

    //Change Currency
    currency_of_preference: "Currency of Preference",
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    // Language
    language_of_preference: "Language of Preference",
    english: "English",
    arabic: "Arabic",

    //Email update
    update_email_text: "Please enter the email you wish to update",
    current_email: "Current Email",
    email_send_code: "Send Code",
    email_verfication_text:
      "Please enter the verification code that we have sent to your email.",
    verification_code_mismatch: "Verification code does not match",
    email_send_code_success: "OTP sent to given Email",
    email_otp_has_been_sent_success: "OTP has been sent",
    verify_email_button: "Verify Email",
    form_validation_email_required: "Please enter valid email address!",

    replace_pay: "You have to pay ",
    replace_refund: "You will be refunded ",
    current_status: "Current Status",
    exchange_order: "Exchange Order",
    exchange_product: "Exchanged Product",
    previous_paid: "Previously Paid",
    selected_price: "Selected Price",
    original_product_price: "Original Product",
    order_details_paid: "Paid Amount",
    order_details_refund: "Refund Amount",
    download_invoice: "Download Invoice",
    no_payment_required: "No payments required",

    ban_account_text: "Your account is temporarily disabled",
    contact_support: "Contact Support",

    //Footer Nav
    contact_us: "Contact Us",
    returns_order: "Returns Order",
    footer_my_account: "My Account",
    support: "Support",
    privacy_policy: "Privacy Policy",
    terms_condition: "Terms & Condition",
    faq: "FAQ",
    return_policy: "Return Policy",
    shipment_policy: "Shipment Policy",
    policy_center: "Policy Center",
    consumer_legal_return_subtext2: "View our return policy",
    consumer_legal_shipment_subtext2: "View our shipment policy",
    intellectual_property: "Intellectual Property Claims",

    copyright_text: `© Shopez E-Commerce L.L.C - 2023. The trademarks and designs
              appearing here are exclusively owned by Shopez E-Commerce L.L.C,
              and/or its licensors, and are protected. Unauthorized use will
              invite legal action.`,
    footer_text: `Shopez is your marketplace. It’s the perfect place to go to
                declutter or discover items that will breathe new life into your
                space. Letting go has never felt so good – it’s almost as good
                as finding your new favorite thing. And we’re here to help`,
    hello_guest: "Hello Guest",
  },
  ar: {
    btn_save: "حفظ",
    search_text: "ما الذي تبحث عنه؟",
    required: "مطلوب",
    done: "اكتمل",
    search_not_found: "لا توجد نتائج لـ",
    breadcrumb_home: "الصفحة الرئيسية",
    breadcrumb_search: "بحث",
    email: "البريد الإلكتروني",
    password: "كلمة المرور",
    conf_password: "تأكيد كلمة المرور",
    signup: "تسجيل الإشتراك",
    first_name: "الاسم الأول",
    last_name: "اسم العائلة",
    nick_name: "اسم المستخدم",
    date_of_birth: "تاريخ الميلاد",
    phone_no: "رقم الهاتف",
    confirm_password: "تأكيد كلمة المرور",
    gender: "الجنس",
    signin: "تسجيل الدخول",
    login_btn_text: "تسجيل الدخول",
    forgot_your_password: "هل نسيت كلمة المرور؟",
    already_account_text: "لديك حساب بالفعل؟",
    login: "تسجيل الدخول",
    signout: "تسجيل الخروج",
    logout_confirm_title: "تسجيل الخروج",
    logout_confirm_message: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    invalid_user: "اسم المستخدم خاطئ!",
    button_logout: "تسجيل الخروج",
    invalid_email_password: "البريد  إلكتروني أو كلمة المرور غير صحيحة!",
    invalid_user: "اسم المستخدم خاطئ!",
    login_form_validation_email_required:
      "الرجاء إدخال عنوان بريدك الإلكتروني!",
    login_form_validation_password_required:
      "الرجاء  إدخال كلمة المرور الخاصة بك!",
    form_validation_first_name_required: "الرجاء  إدخال اسمك الأول!",
    form_validation_last_name_required: "الرجاء  إدخال اسم عائلتك!",
    form_validation_phone_no_required: "الرجاء إدخال رقم هاتفك!",
    form_validation_conf_password_required: "الرجاء إدخال تأكيد كلمة المرور!",
    form_validation_password_not_match:
      "كلمة المرور وتأكيد كلمة المرور لاتتطابق!",
    email_password: "أدخل بريدك الإلكتروني وكلمة المرور لتسجيل الدخول",
    create_a_new_account: "إنشاء حساب",
    my_account: "حسابي",
    categories: "الأقسام",
    subcategories: "الفئة الفرعية",
    show_all: "عرض الكل",
    product_desc: "وصف المنتج",
    product_overview: "نظرة عامة",
    variant: "نوع",
    shipping: "الشحن",
    product_spec: "المواصفات",
    variant_not_selected: "الرجاء اختيار النوع لاستبداله",
    common_fail_message: "حدث خطأ ما!",
    signup_error_already_registered: "البريد الإلكتروني مسجل بالفعل!",
    signup_success: "تم إنشاء الحساب بنجاح!",
    login_success: "تم تسجيل الدخول بنجاح!",
    connect_social_media_text: "ربط حسابك بوسائل التواصل الاجتماعي",
    signup_error_phone_already_registered: "رقم الهاتف مسجل بالفعل",
    signup_heading: "دعنا نبدأ بإنشاء حساب جديد",
    forgot_password: "هل نسيت كلمة المرور؟",
    password_reset_link_text:
      "الرجاء إدخال البريد الإلكتروني المسجل وسنرسل لك رابط إعادة تعيين كلمة المرور",
    send_reset_link: "إرسال رابط إعادة التعيين",
    close: "إغلاق",
    forgot_password_success: "تم إرسال رابط إعادة تعيين كلمة المرور بنجاح",
    reset_btn: "إعادة تعيين",
    reset_password_heading: "إعادة تعيين كلمة المرور",
    reset_confirm_code: "تأكيد الرمز",
    reset_password_success: "تم تحديث كلمة المرور بنجاح",
    forgot_password_form_error_messages:
      "عنوان البريد الإلكتروني غير مسجل في شوبيز",
    products: "المنتجات",
    product_specification: "مواصفات المنتج",
    ask_question_placeholder: "اكتب سؤالك",
    ask_btn: "إرسال",
    ask_question_success: "تم إضافة السؤال",
    ask_question_form_error: "السؤال مطلوب",
    question_and_answer: "الأسئلة والأجوبة",
    question_and_answer_subheading: "هل تريد معرفة المزيد عن المنتج؟",
    ask_question_btn: "اطرح سؤالاً",
    view_all_qa_link: "عرض جميع الأسئلة والأجوبة",
    all_question_text: "سؤال(ات)",
    view_all_question_link: "تحميل المزيد من الأسئلة",
    reviews: "المراجعات",
    view_all_review_link: " تحميل المزيد من المراجعات",
    all_review_text: "جميع المراجعات",
    view_all_details: "عرض جميع التفاصيل",
    reviews_txt: "المراجعات",
    review_txt: "مراجعة",
    review_all_text: "/5.0",
    review_recommeded_text: "موصى به",
    review_search_text: "بحث",
    review_sortby_low_to_high: "السلبية أولاً",
    review_sortby_high_to_low: "الإيجابية أولاً",
    review_sortby_newest_first: "الأحدث أولاً",
    sold_txt: "مُباع",
    wishlist_txt: "أضف الى قائمة الرغبات",
    wishlisted_txt: "تم الإضافة إلى قائمة الرغبات",
    watchlist_txt: "قائمة المراقبة",
    watching_txt: "في المراقبة",
    watchlist_remove_success: "تمت إزالة العنصر من قائمة المراقبة",
    watchlist_add_success: "تمت إضافة العنصر إلى قائمة المراقبة",
    watchlist_delete_item_success: "تم حذف العنصر من قائمة المراقبة",
    product_quantity_text: "الكمية",
    wishlist_clear_success: "تم إزالة المنتج من قائمة والرغبات ",
    wishlist_delete_success: "تم حذف قائمة الرغبات",
    wishlist_delete_item_success: "تم حذف المنتج من قائمة الرغبات",
    wishlist_delete_title: "حذف قائمة الرغبات",
    wishlist_delete_msg: "هل أنت متأكد أنك تريد حذف قائمة الرغبات هذه؟",
    wishlist_clear_title: "إزالة المنتج من قائمة الرغبات",
    wishlist_clear_msg: "هل  أنت متأكد أنك تريد إزالة المنتجات؟",
    button_yes: "نعم",
    button_no: "لا",
    button_ok: "حسناً",
    button_delete: "حذف ",
    button_edit: "تعديل",
    button_edit_profile: "تعديل الملف الشخصي",
    item: "منتج",
    items: "منتجات",
    wishlist_share_success: "تم مشاركة قائمة الرغبات",
    wishlist_item_delete_option: "حذف المنتجات",
    wishlist_clear_option: "مسح قائمة الرغبات",
    wishlist_delete_option: "حذف قائمة الرغبات",
    wishlist_share_option: "مشاركة قائمة الرغبات",
    wishlist_no_product: "لم يتم إضافة أي منتج(ات) بعد",
    wishlist_remove_success: "تمت إزالة المنتج من قائمة الرغبات",
    wishlist_add_success: "تم إضافة المنتج إلى قائمة الرغبات",
    wishlist_slider_title: "أضف إلى قائمة الرغبات",
    create_new_wishlist: "أنشئ قائمة رغبات جديدة",
    create_new_wishlist_name: "اسم قائمة الرغبات",
    create_new_wishlist_privacy: "الخصوصية",
    create_new_wishlist_public: "عام",
    create_new_wishlist_private: "خاص",
    create_new_wishlist_btn: "حفظ قائمة الرغبات",
    create_new_wishlist_name_required: "الاسم مطلوب",
    wishlist_name_limit_error: "٣٠ حرفاً فقط",
    category: "الفئة",
    sub_category: "الفئة الفرعية",
    condition: "الحالة",
    share_txt: "مشاركة",
    shop_now: "تسوق الآن",
    similar_products: "منتجات مشابهة",
    similar_products_not_found: "لم يتم العثور على منتجات مشابهة",
    view_all_similar_products: "عرض جميع المنتجات المشابهة",
    you_may_like_products: "منتجات قد تعجبك",
    rating: "تقييم",
    and: "و",
    star: "نجمة",
    share_heading: "مشاركة",
    starts_in: "يبدأ في",
    ends_in: "ينتهي في",
    bid_not_started: "لم يبدأ المزاد",
    bid_over: "انتهى",
    expired: "منتهي",
    out_of_stock: "غير متوفر في المخزون",
    notify_available_text: "أعلموني عند التوفر",
    cancelled: "ملغي",
    not_deliverable: "غير قابل للتوصيل",
    temporarily_unavailable: "غير متوفر مؤقتاً",
    place_a_bid: "ضع مزايدة",
    cancel: "إلغاء",
    make_offer: "قدم مزايدتك",
    place_bid: "ضع مزايدة",
    bid_private_text: "احتفظ بأسمي مجهولاً",
    bid_commited_text:
      "من خلال تقديم عرض المزايدة، فإنك تلتزم بشراء المنتج إذا فزت.",
    place_a_bid_price_error: "السعر مطلوب",
    place_a_bid_min_price_error:
      " يجب أن يكون السعر مساويًا أو أعلى من أعلى مزايدة",
    highest_bid: "أعلى مزايدة",
    your_last_bid: "مزايدتك الأخير",
    starting_bid: "سعر البدء",
    bid: "المزايدات",
    place_a_bid_commited_error: "مطلوب",
    place_re_bid: "إعادة تقديم مزايدة",
    view_comments_btn: "تعليق",
    comment: "تعليقات",
    likes_text: "إعجابات",
    like_text: "إعجاب ",
    delivery: "التوصيل",
    deliverto: "التوصيل إلى",
    deliveryby: "التوصيل بواسطة",
    soldby: "تم بيعة بواسطة",
    follower: "المتابعون",
    following: "متابعاتك",
    follow_btn: "متابعة",
    unfollow_btn: "إلغاء متابعة",
    send_message_btn: "إرسال رسالة",
    return_policy: "سياسة الإرجاع",
    replacement_policy: "سياسة الاستبدال",
    add_to_cart_success: "تم إضافة المنتج إلى السلة",
    choose_variant: "اختر الأنواع",
    selected_variant: "النوع المحدد",
    add_to_cart_btn: "أضف إلى السلة",
    slider_title: "تفاوض",
    negotiate_btn: "فاوض واشتر",
    quantity_not_available: "الكمية غير متاحة",
    negotiate_view_btn: "عرض التفاوض",
    order_placed_success: "تم تقديم الطلب بنجاح",
    step1: "السلة",
    step2: "العنوان",
    step3: "الدفع",
    negotiation_ini_success_message: "تم بدء التفاوض",
    negotiation_quantity_text: "الكمية",
    negotiation_pending_text: "في انتظار التأكيد",
    negotiation_rejected_text: "تم رفض العرض",
    negotiation_accepted_text: "تم قبول العرض",
    negotiation_closed_text: "تم إغلاق العرض",
    negotiation_enter_price_text: "أدخل سعرك",
    negotiation_attempt_left_text: "محاولة متبقية",
    negotiation_proceed_purchase_btn: "استكمل عملية الشراء",
    negotiation_status: "الحالة",
    negotiated_won_variant_error:
      "النوع الذي تم الفوز به في التفاوض موجود بالفعل في السلة",
    shipping_and_taxes_extra: "الشحن والضرائب إضافية",
    negotiation_text: "تفاوض",
    negotiation_not_found: "لاتوجد مفاوضات",
    negotiation_active: "نشط",
    negotiation_won: "فاز",
    negotiation_lost: "خسر",
    negotiation_won_text: "تم الفوز بالتفاوض",
    negotiation_won_title: "لا توجد مفاوضات تم الفوز بها",
    negotiation_won_desc: "لم تفز بأي مفاوضة",
    negotiation_lost_title: "لاتوجد مفاوضات ضائعة",
    negotiation_lost_desc: "لم تخسر أي مفاوضة",
    negotiation_active_title: "لاتوجد مفاوضات",
    negotiation_active_desc: "لا يوجد أي منتج تم التفاوض عليه بعد",
    negotiation_note: "ستتلقى إشعارًا بمجرد أن يرد البائع.",
    watchlist_tab_watching: "المراقبة",
    watchlist_tab_mybids: "مزاداتي",
    mybids_not_found: "لا توجد مزايدات",
    mention_slider_title: "Mentions",
    mention_no_comment: "لاتوجد تعليقات بعد",
    mention_add_success: "تم نشر التعليق بنجاح",
    mention_update_success: "تم تحديث التعليق بنجاح",
    activity: "الانشطة",
    shop: "تسوق",
    activity_you: "أنت",
    buying_history: "سجل المشتريات",
    activity_followers: "المتابعون",
    acitvity_recently_viewed: "العناصر التي تم مشاهدتها مؤخرًا",
    acitvity_watchlist: "قائمة المراقبة",
    acitvity_wishlist: "قائمة الرغبات",
    recently_viewed_not_found: "لا توجد عناصر تم مشاهدتها مؤخرًا",
    buying_history_purchases: "المشتريات",
    buying_history_purchase_made: "المشتريات المنجزة",
    buying_history_no_purchases: "لم يتم إجراء أي عملية شراء بعد",
    buying_history_last_purchases: "آخر صفقة شراء تم إنجازة",
    buying_history_bids: "المزايدات",
    buying_history_bids_placed: "المزايدات المقدمة",
    buying_history_no_bids: "لم يتم تقديم أي مزايدات بعد",
    buying_history_last_bid: "آخر عرض مزايدة مُقدمة",
    buying_history_offers: "العروض",
    buying_history_offers_negotiation: "عروض التفاوض",
    buying_history_no_negotiation: "لم تُجرَ أي مفاوضات بعد",
    buying_history_last_offer: "آخر عرض تم تقديمة",
    buying_ago: "منذ",
    watcher_list: "قائمة المراقبة",
    view_my_orders: "عرض طلباتي",
    view_my_bids: "عرض مزايداتي",
    view_negotiation: "عرض المفاوضات",
    public_wishlist_feed: "إنشاء قائمة رغبات عامة",
    public_mention_feed: "منشن",
    new_product_feed: "إنشاء منتج جديد",
    coupon_feed: "إنشاء كوبون قادم",
    discount_feed: "إنشاء خصم قادم",
    no_bids_won_title: "ليس لديك مزايدات فزت بها",
    no_bids_won_desc: "لم تفز بأي مزايدة",
    no_bids_lost_title: "ليس لديك مزايدات خاسرة",
    no_bids_lost_desc: "لم تخسر أي مزايدة",
    no_bids_active_title: "لا توجد مزايدات نشطة",
    no_bids_active_desc: "ليس لديك أي مزايدات نشطة",
    no_bids_title: "لا توجد مزايدات",
    no_bids_desc: "لاتوجد منتجات",
    no_watching_title: "أنت لا تتابع أي مزايدة",
    no_watching_desc: "لا توجد قوائم رغبات مشتركة",
    no_wishlist_title: "ليس لديك أي قائمة رغبات مشتركة حتى الآن",
    no_wishlist_desc: "You don't have any shared wishlist yet",
    my_followers: "متابعيني",
    my_followers_no_data_title: "لا يوجد متابعون",
    my_followers_no_data_desc: "ليس لديك أي متابعين حتى الآن",
    shared_wishlist_tab_key: "مُشترَك",
    following_tab_key: "متابعاتك",
    shared_wishlist_tab: "قائمة رغبات مشتركة",
    following_tab: "متابعاتك",
    shared_wishlist_tab: "قائمة الرغبات المشتركة",
    following_tab: "متابعاتك",
    shared_no_data_title: "لا توجد قوائم رغبات مشتركة",
    shared_no_data_desc: "لم يقم المستخدم بمشاركة أي قائمة رغبات",
    you_shared_no_data_desc: "ليس لديك أي قائمة رغبات مشتركة حتى الآن",
    following_no_data_title: "لا توجد متابعات",
    following_no_data_desc: "المستخدم لا يتابع أي مستخدم",
    you_following_no_data_desc: "أنت لا تتابع أي مستخدم",
    based_interest: "بناءً على اهتماماتك",
    followed: "followed",
    menu_home: "الرئيسية",
    menu_profile: "إعدادات الملف الشخصي",
    menu_language: "اللغة",
    menu_currency: "العملة",
    menu_categories: "جميع الفئات",
    menu_order: "طلباتي",
    menu_bidplace: "المزايدات المقدمة",
    menu_negotiation: "المفاوضات",
    menu_coupon: "كوبوناتي",
    menu_invitation: "دعواتي",
    menu_suggestion: "الاقتراحات",
    menu_faq: "الأسئلة الشائعة والدعم",
    menu_legal: "الوثائق القانونية",
    menu_signin: "تسجيل الدخول",
    menu_signout: "تسجيل الخروج الدخول",
    message_title: "الرسائل",
    send_message: "إرسال رسالة",
    type_message: "اكتب رسالتك",
    no_message_header: "لا توجد رسائل حتى الآن",
    no_message_subheader: " ترقبوا! ستظهر الرسائل هنا",
    message_tab_product: "المنتجات",
    message_tab_order: "الطلبات",
    no_review_header: "لا توجد مراجعات بعد",
    seller_profile: "صفحة البائع",
    topcategories: "فئات بارزة",
    top_selling_item: "أكثر المنتجات مبيعاً",
    rating_from: "من",
    location: "الموقع",
    member_shopez: "عضو في شوبيز منذ",
    item_as_described: "المنتج كما تم وصفه",
    communication: "التواصل",
    shipping_time: "مدة الشحن",
    map: "الخريطة",
    select_language: "اختر اللغة",
    topsel_low_to_high: "أكثر المنتجات مبيعًا من الأدنى إلى الأعلى",
    topsel_high_to_low: "أكثر المنتجات مبيعًا من الأعلى إلى الأدنى",
    away_timing: "فترة عدم التواجد",
    anonymous: "مجهول",
    cart_address: "العنوان",
    cart_text: "سلة التسوق",
    cart_breadcrumb: "السلة",
    cart_applied_coupons: "الكوبونات المستخدمة",
    cart_get_coupon: "احصل على الكوبون",
    cart_proceed_checkout: "تابع إلى الدفع",
    cart_continue_shopping: "تابع التسوق",
    cart_continue: "متابعة",
    cart_order_summery: "ملخص الطلب",
    cart_discount: "الخصم",
    cart_coupon_code: "كود الكوبون",
    cart_shipping_fee: "رسوم الشحن",
    cart_tax_charges: "الضرائب والرسوم",
    cart_total: "الإجمالي",
    cart_shipping_address: "عنوان الشحن",
    cart_payment: "الدفع",
    cart_payment_method: "طريقة الدفع",
    cart_payment_card: "الدفع ببطاقة",
    cart_payment_cash: "الدفع نقداً",
    cart_payment_cash_not_available: "الدفع النقدي غير متاح",
    cart_address_change_btn: "تغيير",
    cart_new_address: "عنوان جديد",
    cart_item_selected: "المنتجات المحددة",
    cart_sku: "رمز المنتج",
    cart_remove: "حذف",
    cart_product_price: "سعر المنتج",
    cart_product_discount: "الخصم",
    cart_coupon_discount: "خصم الكوبون",
    cart_available_coupons: "الكوبونات المتاحة",
    cart_flat_discount: "ثابت",
    cart_coupon_apply: "تطبيق",
    cart_coupon_expired: "منتهي",
    cart_coupon_min_error:
      "يجب أن تكون الحد الأدنى لقيمة السلة مساوية أو أكبر من",
    cart_item_not_selected: "لم يتم اختيار أي منتج",
    cart_coupon_applied_success: "تم تطبيق رمز الكوبون بنجاح",
    cart_coupon_error1: "تم استخدام الكوبون بالفعل!",
    cart_coupon_error2: " لا يمكن استخدامه على المنتجات الموجودة!",
    cart_coupon_error3: "تم نفاذ جميع الكميات المتاحة من هذا الكوبون!",
    cart_coupon_error4: "لا يمكن استخدام الكوبون!",
    cart_coupon_copied: "تم نسخ الكوبون",
    cart_coupon_not_available: "الكوبون غير متاح!",
    cart_coupon_has_expired: "انتهت صلاحية الكوبون!",
    cart_coupon_not_exist: "رمز الكوبون غير موجود!",
    cart_billing_address: "عنوان الفواتير",
    cart_review_items: "مراجعة المنتجات",
    same_as_shipping: "ذات عنوان الشحن",
    cart_select_shipping_address: "اختر عنوان الشحن",
    contact_name: "اسم جهة الاتصال",
    country: "البلد",
    address: "العنوان",
    city: "المدينة",
    zip: "الرمز البريدي",
    state: "الولاية",
    review_order: "مراجعة طلبك",
    make_default_title: "اجعلها الافتراضية",
    make_default_msg: "هل تريد جعل هذا عنوانًا افتراضيًا؟",
    button_once: "لا، استخدم مرة واحدة",
    button_add_new_address: "إضافة عنوان جديد",
    button_edit_address: "تعديل العنوان",
    button_update_address: "تحديث",
    button_save_address: "حفظ العنوان",
    empty_cart_title: "سلة التسوق الخاصة بك فارغة!",
    empty_cart_desc: "أضف المنتجات إليها الآن!",
    cart_coupon_applied: "الكوبون المطبق",
    cart_place_order: "إتمام الطلب",
    cart_no_address: "لا يوجد عنوان",
    cart_add_address: "يرجى إضافة عنوان",
    available_address: "عنوان",
    buildingno: "رقم المبنى",
    zoneno: "رقم المنطقة",
    streetno: "رقم الشارع",
    unitno: "رقم الوحدة/ صندوق البريد",
    order_not_proceed:
      "بعض  المنتجات الخاصة بك إما غير متوفرة في المخزون أو غير متاحة مؤقتًا",
    report_as_abusive: "الإبلاغ عن إساءة",
    report_title: "بلّغ",
    report_button: "بلّغ",
    report_confirm_product_title: "بلّغ عن المنتج",
    report_confirm_product_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذا المنتج؟",
    report_success: "لقد قمت بالإبلاغ عن إساءة بنجاح",
    report_confirm_comment_title: "بلّغ عن تعليق",
    report_confirm_comment_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذا التعليق؟",
    report_confirm_review_title: "بلّغ عن المراجعة",
    report_confirm_review_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذه المراجعة؟",
    report_confirm_question_title: "بلّغ عن السؤال",
    report_confirm_question_message:
      " هل أنت متأكد أنك تريد الإبلاغ عن هذا السؤال؟",
    report_confirm_answer_title: "بلّغ عن الإجابة",
    report_confirm_answer_message:
      " هل أنت متأكد أنك تريد الإبلاغ عن هذه الإجابة؟",
    report_confirm_consumer_title: "بلّغ عن المستخدم",
    report_confirm_consumer_message:
      "هل أنت متأكد أنك تريد الإبلاغ عن هذا المستخدم؟",
    coupon_copy: "نسخ",
    my_coupons: "كوبوناتي",
    product_coupons: "الكوبونات",
    my_orders: "طلباتي",
    order_id: "رقم الطلب",
    order_details_pay_now: "ادفع الآن",
    order_details_btn: "التفاصيل",
    order_details: "تفاصيل الطلب",
    order_delivered: "تم التوصيل",
    order_dispatched: "تم الشحن",
    order_Placed: "تم تسجيل الطلب",
    order_cancelled: "ملغى",
    initiate_refund: "بدء عملية الاسترداد",
    initiate_pickup: "تم بدء عملية الاستلام",
    return_received: "تم استلام المنتج المرتجع في شوبيز",
    return_picked: "تم استلام المنتج المرتجع من العميل",
    return_requested: "تم رفع طلب استرداد المنتج",
    replacement_requested: "تم رفع طلب استبدال المنتج",
    refund_initiated: "بدء عملية الاسترداد",
    refunded: "استرداد المبلغ",
    payment_pending: "لم يُعالج بعد",
    refund_rejected: "تم رفض طلب استرداد المبلغ",
    reshipdelivered: "تم تسليم الطلب",
    reship: "تم استبدال المنتج وإعادة شحنه",
    in_transit: "قيد الشحن",
    seller: "البائع",
    sku: "رمز المنتج",
    quantity: "الكمية",
    inclusive_text: "شامل جميع الضرائب والرسوم",
    qar_total_text: "سيتم خصم المبلغ بالريال القطري والإجمالي=",
    order_status: "حالة الطلب",
    contact_no: "رقم الاتصال",
    payment_method: "الدفع",
    rate_delivaery: "تقييم عملية التوصيل",
    btn_return: "إرجاع ",
    btn_cancel: "إلغاء",
    btn_return_replace: "إرجاع/استبدال",
    btn_replace: "استبدال",
    btn_reorder: "إعادة الطلب",
    confirm_return_replace: "هل أنت متأكد أنك تريد إرجاع/استبدال؟",
    confirm_refund: "هل أنت متأكد أنك تريد الإرجاع؟",
    confirm_replace: "هل أنت متأكد أنك تريد الاستبدال؟",
    confirm_cancel: "هل أنت متأكد أنك تريد الإلغاء؟",
    cancel_reason: "سبب الإلغاء",
    refund_cancel_reason: "سبب رفض عملية استرداد المبلغ",
    order_cancel: "تم إلغاء الطلب",
    cancel_note: "ستتلقى إشعارًا بمجرد تحديث الحالة",
    reject_reason: "سبب رفض استرداد المبلغ",
    return_reason: "سبب الإرجاع/الاستبدال",
    return_title: "إرجاع المنتج",
    return_heading_text: "ماذا تريد أن تفعل بالمنتج؟",
    replace_product: "استبدال المنتج",
    return_product: "إرجاع المنتج",
    confirm_request: "تأكيد الطلب",
    confirm_and_pay: "التأكيد والدفع",
    select_reason_text: "اختر سبب الاستبدال",
    applicable_text: "حدد كافة الأسباب التي تنطبق",
    explain_placeholder: "اشرح الأسباب",
    reason_item_match: "تم استلام المنتج بحالة تالفة",
    reason_item_color: "تم استلام لون/موديل خاطئ",
    reason_item_damage: "تم استلام المنتج بحالة تالفة",
    reason_other: "هل لديك سبب آخر؟ يرجى كتابة سببك",
    return_status_error: "الحالة مطلوبة",
    return_reason_error: "السبب مطلوب",
    select_reason_error: "اختر أي سبب",
    cancel_success: "تم إلغاء المنتج بنجاح",
    return_success: "تم تقديم طلب إرجاع المنتج بنجاح",
    replace_success: "تم تقديم طلب استبدال المنتج بنجاح",
    cancel_request: "إلغاء الطلب",
    cancel_btn: "إلغاء ",
    no_order_title: "لا توجد طلبات",
    no_order_desc: "لم تقم بتقديم أي طلب بعد",
    order_success: "تم تقديم الطلب بنجاح",
    rate_product: "تقييم المنتج",
    rate_product_rating: "تقييم المنتج (من الأدنى إلى الأعلى)",
    rate_product_desc:
      "يرجى تقييم المعايير أدناه وفقًا لتجربتك (من الأدنى إلى الأعلى)",
    rate_product_point1: "ما مدى دقة وصف المنتج؟",
    rate_product_point2: " ما مدى رضاك عن تواصل البائع؟",
    rate_product_point3: "كم كانت سرعة شحن البائع للمنتج؟",
    rate_product_point4: "يمكنك ترك ملاحظاتك",
    rate_product_attach: "إرفاق الملفات",
    rate_product_anonymus: "تقديم الملاحظات بشكل مجهول",
    rate_product_error: "يرجى تقييم المنتج",
    submit_rating: "إرسال التقييم",
    review_submit_success: "تم تقديم المراجعة بنجاح. شكراً لك!",
    cancel_refund: "تم الشروع في إيقاف عملية استرداد المبلغ",
    cancel_refunded: "إلغاء طلب استرداد المبلغ",
    cancel_refundrejected: "تم رفض طلب استرداد المبلغ",
    cancel_return: "إلغاء إرجاع المنتج",
    refund_processing: "معالجة طلب استرداد المبلغ",
    no_product_title: "لا توجد منتجات",
    no_product_desc: "لم يتم إضافة أي منتج بعد",
    product_title: "المنتجات",
    item_text: "منتج",
    items_text: "منتجات",
    sortby: "الترتيب حسب",
    sortby_low_to_high: "السعر من الأدنى إلى الأعلى",
    sortby_high_to_low: "السعر من الأعلى إلى الأدنى",
    sortby_popularity: "الشهرة",
    sortby_newest_first: "الأحدث أولاً",
    sortby_bestmatch: "أفضل توافق",
    sortby_endingsoonest: "ينتهي قريبًا",
    price_title: "السعر",
    filter_text: "تصفية",
    clear_all: "مسح الكل",
    no_product_found: "لم يتم العثور على منتجات",
    profile_settings_list_text1: "معلومات الملف الشخصي",
    profile_settings_list_subtext1: "إدارة الملف الشخصي",
    profile_settings_list_text2: "العنوان",
    profile_settings_list_subtext2: "إدارة عنوانك",
    profile_settings_list_text3: "الإشعار",
    profile_settings_list_subtext3: "تفعيل",
    profile_settings_list_text4: "إعدادات الحساب",
    profile_settings_list_subtext4: "إدارة حسابات الدفع",
    profile_settings_list_text5: "متابعيني",
    profile_settings_list_subtext5: "شاهد من يتابعك",
    update_account: "تحديث الحساب",
    profile_settings_actsettings_terms_text: `تُجمع جميع أرباحك في شوبيز وسيتم إرسالها إليك حسب دورة مدتها 7 أيام. يُرجى إضافة تفاصيل حسابك
 البنكي حيث تود استلام المدفوعات`,
    beneficiary_name: "اسم المستفيد",
    beneficiary_full_name: "الاسم الكامل للمستفيد",
    bank_name: "اسم البنك",
    beneficiary_bank_name: "اسم بنك المستفيد",
    act_settings_act_number_lbl: "رقم الحساب/IBAN",
    act_settings_act_number_placeholder: "رقم حساب المستفيد/IBAN",
    act_settings_swift_code_lbl: "Swift code/Bic code/sort code",
    act_settings_swift_code_placeholder: "Swift code/Bic code/sort code",
    select_country: "اختر البلد",
    act_settings_contact_number_lbl: "رقم الاتصال",
    act_settings_contact_number_placeholder: "رقم اتصال المستفيد (اختياري)",
    text_you: "أنت",
    btn_follow: "متابعة",
    btn_unfollow: "إلغاء المتابعة",
    profile_settings_myfollowers_sheardwhishlist: "قائمة الرغبات المشتركة",
    profile_settings_myfollowers_Following: "متابعاتك",
    no_shared_wishlist: "لا توجد قوائم رغبات مشتركة",
    user_havenot_shared_wishlist: "لم يقم المستخدم بمشاركة أي قائمة رغبات",
    no_following: "لا توجد متابعات",
    user_not_following_user: "المستخدم لا يتبع أي مستخدم آخر",
    items: "المنتجات",
    update_profile: "تحديث الملف الشخصي",
    get_verified: "وثق حسابك",
    no_rating: "لا يوجد تقييم",
    your_profile_is_unverified: "ملفك الشخصي غير موثق",
    profile_updated_successfully: "تم تحديث معلومات الملف الشخصي بنجاح",
    add_phone_number: "إضافة رقم الهاتف",
    update_password: "تحديث كلمة المرور",
    update_phone_number: "تحديث رقم الهاتف",
    verify_phone_number: "تحقق",
    phone_updated_successfully: "تم تحديث رقم الهاتف بنجاح",
    otp_verified_successfully: "تم التحقق من رمز الــOTP بنجاح",
    otp_not_verified: "لم يتم التحقق من رمز الــOTP",
    mobile_not_verified: "لم تقم بالتحقق من رقم الهاتف المحمول بعد",
    update_email: "تحديث البريد الإلكتروني",
    email_updated_successfully: "تم تحديث البريد الإلكتروني بنجاح",
    email_not_verified: " لم تقم بالتحقق من البريد الإلكتروني بعد.",
    update_store_info: "تحديث معلومات المتجر",
    contact_person_label: "جهة الاتصال",
    contact_person_placeholder: "اسم جهة الاتصال",
    contact_number_label: "رقم الاتصال",
    another_number_label: "رقم اتصال آخر",
    add_another_number_btn: "إضافة رقم آخر",
    select_away_time: "اختر وقت الغياب",
    store_info_start_date_label: "تاريخ البدء",
    store_info_end_date_label: "تاريخ الانتهاء",
    set_automatic_response_text: "تعيين رد تلقائي",
    set_automatic_response_subtext:
      " (الرد التلقائي للأشخاص الذين يتواصلون عبر الرسائل أثناء فترة الغياب)",
    set_automatic_response_placeholder: "مرحبا، كيف تسير يومك؟",
    store_updated_successfully: "تم تحديث معلومات المتجر بنجاح",
    delete_contact_confirm: "حذف رقم الاتصال",
    delete_contact_number_confirm_text:
      "هل أنت متأكد أنك تريد حذفه؟ لا يمكن التراجع عن هذا الإجراء",
    contact_deleted_successfully: "تم حذف جهة الاتصال بنجاح",
    plus_add_another_number: "(+) إضافة رقم آخر",
    location_header: "الموقع",
    store_location_text: "موقع المتجر",
    search_places: "البحث عن الأماكن ...",
    search_address: "البحث عن العنوان",
    search_your_location: "ابحث عن موقعك",
    latitude: "خطوط العرض",
    longitude: "خطوط الطول",
    zoom: "تكبير",
    address: "العنوان",
    current_password: "كلمة المرور الحالية",
    confirm_password: "تأكيد كلمة المرور",
    security_code: "كلمة المرور الحالية",
    phone_number: "رقم الهاتف",
    update_phone_number_text: "يُرجى إدخال الرقم الذي ترغب في تحديثه",
    current_number: "الرقم الحالي",
    send_code: "إرسال الرمز",
    phone_number_verfication_text:
      "يُرجى إدخال رمز التحقق الذي أرسلناه إلى رقم هاتفك.",
    verification_code_mismatch: "رمز التحقق غير متطابق",
    store_location_verfication_text: "يُرجى إدخال موقع المتجر",
    send_code_success: " تم إرسال رمز التحقق (OTP) إلى الرقم المحدد",
    otp_has_been_sent_success: "تم إرسال رمز التحقق (OTP)",
    submit_verification: "تقديم طلب توثيق الحساب",
    attach_files: "إرفاق الملفات",
    attach_document: "إرفاق المستند",
    commercial_registration_document: "مستند السجل التجاري",
    agreement_template_duly_filled_and_signed:
      "نموذج الاتفاقية مكتمل وموقع بشكل صحيح",
    store_verification_form_header:
      "إذا كنت ترغب في أن تصبح بائعًا موثوقًا وتحقق المزيد من الأرباح، قم بتوثيق حسابك على شوبيز!",
    store_verification_form_subheader:
      "قم برفع المستندات لتحصل على علامة التوثيق",
    on_your_profile: "في ملفك الشخصي",
    vendor_verified_text: "أنت مستخدم معتمد وموثق",
    download_agreement_template: "تحميل نموذج الاتفاقية",
    valid_id_proof: "إثبات هوية شخصية سارية",
    upload_only_doc_and_pdf_file_error_text:
      "ملف غير صالح! يرجى تحميل ملفات بصيغة doc و pdf فقط",
    attachfile_to_update_error_text: "إرفاق الملفات للتحديث",
    app_suggestions_header_text: "اختر اقتراحاتك",
    app_suggestions_lbl_email_address: "عنوان البريد الإلكتروني",
    app_suggestions_plshldr_email_address: "عنوان البريد الإلكتروني",
    app_suggestions_lbl_suggestion_desc: "وصف الاقتراح",
    app_suggestions_plshldr_suggestion_desc: "إضافة عناوين البريد الإلكتروني",
    app_suggestions_footer_text:
      "سيتم إرسال اقتراحك إلى شوبيز وسيتم اتخاذ الإجراءات اللازمة.",
    send_suggestion: "إرسال الاقتراح",
    file_attached: "تم إرفاق الملف ",
    files_attached: "تم إرفاق الملفات",
    bug: "خطأ/خلل فني",
    app_feature: "ميزة",
    app_suggestions_submit_success: "تم إرسال اقتراحاتك بنجاح",
    raise_a_ticket: "تقديم طلب دعم",
    faq_footer_head_text: "هل تحتاج إلى مزيد من المساعدة؟ اتصل بنا",
    call_us: "اتصل بنا",
    write_to_us: "راسلنا",
    raise_tacket_sub_header_text:
      "إذا لم تحصل على إجابة لمشاكلك، يرجى مراسلتنا",
    write_your_problems: "دوّن مشاكلك؟",
    write_your_questions: "دوّن سؤالك؟",
    search_text_faq_support: "ابحث في الأسئلة الشائعة لدينا",
    faq_support_submit_success: "تم تقديم استفسارك بنجاح",
    consumer_legal_docs_list_text1: "الشروك والأحكام",
    consumer_legal_docs_list_subtext1: "اطّلع على الشروط والأحكام لدينا",
    consumer_legal_docs_list_text2: "سياسة الخصوصية",
    consumer_legal_docs_list_subtext2: "اطلع على سياسة الخصوصية لدينا",
    consumer_legal_docs_list_text3: "سياسة المبيعات",
    consumer_legal_docs_list_subtext3:
      "اطلع على سياسة الإرجاع/الاستبدال/المزايدة لدينا",
    consumer_legal_docs_list_text4: "من نحن؟",
    consumer_legal_docs_list_subtext4: "اكتشف قصتنا",
    male: "ذكر",
    female: "أنثى",
    rather_not_say: "أريد أن أبقى مجهولاً",
    password_updated_success: "تم تغيير كلمة السر بنجاح",
    nsetting_auction_updates: "أعلمني عند إرسال تحديثات المزاد للمنتج",
    nsetting_aubid_or_win:
      "أعلمني عندما يتم تجاوز مزايدتي أو أفوز بمنتج في المزايدة",
    nsetting_interested_updated: "عند إجراء تحديث على المنتج الذي يهمني",
    nsetting_order_update:
      "تحديثات الطلب - عند إجراء تحديث على الطلب الذي قمت به",
    nsetting_receive_message: "عندما أتلقى رسالة من عضو في شوبيز",
    nsetting_top_deal: "صفقة اليوم من شوبيز",
    nsetting_followed: "أحدهم يتابعني",
    nsetting_invited: "شخص قمت بدعوته ينضم",
    nsetting_mentioned: "شخص قد أشار إلي (منشن) في المنتج",
    nsetting_violation: "استلام رسالة انتهاك من الإدارة",
    pay_pending_title: "عملية الدفع معلقة",
    pay_pending_desc: "المعاملة معلقة",
    pay_pending_bank_title: "عملية الدفع معلقة",
    pay_pending_bank_desc: "المعاملة معلقة في انتظار تأكيد البنك",
    pay_fail_title: "تعذر إتمام الدفع",
    pay_fail_desc: "تعذر إتمام المعاملة",
    pay_cancelled_title: "تم إلغاء عملية الدفع",
    pay_cancelled_desc: "تم إلغاء المعاملة",
    pay_success: "معاملتك تمت بنجاح",
    address_delete_title: "حذف العنوان",
    address_delete_msg: "هل أنت متأكد أنك تريد حذف هذا العنوان؟",
    address_add_success: "تم إضافة العنوان",
    address_update_success: "تم تحديث العنوان",
    drop_or_select: "قم بالسحب أو الاختيار",
    phone_exist: "رقم الهاتف مُسجل بالفعل",
    notifications: "الإشعارات",
    read_all: "قراءة الكل",
    update: "تحديث",
    mark_all_messages_as_read: "وضع علامة على جميع الرسائل كمقروءة",
    no_notification_header: "لا توجد إشعارات",
    no_notification_subheader: "لم يتم استلام أي إشعار بعد",
    change_currency: "تغيير العملة",
    select_currency: "اختر العملة",
    qatari_riyal: "الريال القطري",
    dollar: "الدولار الأمريكي",
    country_code: "اختر البلد",
    check_digits: "تحقق من الأرقام",
    bank_code: "SWIFT Code/BIC Code/Sort Code",
    account_number: " رقم حساب المستفيد/IBAN",
    iban: "IBAN",
    add_account: "إضافة حساب",
    invalid_length: "غير صالح",
    account_add_success: "تم إضافة الحساب بنجاح",
    account_update_success: "تم تحديث الحساب بنجاح",
    benificiary_name: "اسم المستفيد",
    benificiary_bankname: "اسم بنك المستفيد",
    benificiary_contactno: "رقم هاتف المستفيد",
    my_invitation: "دعواتي",
    invite_message: "رسالة الدعوة",
    email_invitation: `ادعُ أصدقائك عن طريق إضافة عناوين البريد الإلكتروني. يمكنك إضافة عدة عناوين مفصولة بفاصلة (,) 
وإرسال الدعوات دفعة واحدة.`,
    send_invitation: "إرسال الدعوة",
    invitation_submit_success: "تم إرسال الدعوة بنجاح",
    readmore: "اقرأ المزيد",
    readless: "اقرأ أقل",
    signup_as_vendor: "التسجيل كبائع",
    vendor_mode: "نمط البائع",
    verifyemail_success: "تم التحقق من البريد الإلكتروني بنجاح",
    verifyemail_already: "البريد الإلكتروني تم التحقق منه بالفعل",
    skip: "تخطي",
    verification_header_text1: "تحقق من البريد الإلكتروني",
    verification_subheader_text1: "تحقق من عنوان بريدك الإلكتروني",
    verification_header_text2: "تحقق",
    verification_subheader_text2: "تحقق من رقم هاتفك",
    verification_header_text3: "رفع المستندات",
    verification_subheader_text3:
      "قبل أن تتمكن من البدء في استخدام شوبيز، يرجى رفع المستندات التالية",
    verification_email_note_text1: `لكي نقوم بتفعيل حسابك ونجعلك قادرًا على تسجيل الدخول، نحتاج إلى التحقق من عنوان البريد 
الإلكتروني`,
    verification_email_note_text2:
      "لقد أرسلنا بريدًا إلكترونيًا إلى () يحتوي على رابط يجب عليك الضغط عليه",
    verification_email_note_text3:
      "لقد أدخلت عنوان بريد إلكتروني خاطئ. يمكنك تغيير البريد الإلكتروني الآن",
    verification_email_note_text4: `لقد تم تحديث بريدك الإلكتروني الجديد بنجاح! يرجى تسجيل الدخول مرة أخرى للتحقق من بريدك 
الإلكتروني الجديد`,
    verification_email_resend_btn: "لم تستلم البريد الإلكتروني؟ أعد الإرسال",
    verification_phone_change_number: "هل تريد تغيير الرقم؟",
    verification_phone_change_number_field_name: "تغيير الرقم",
    verification_phone_change_number_field_placeholder: "أدخل رقمك",
    enter_wrong_emailid: "لقد أدخلت عنوان بريد إلكتروني خاطئ",
    email_varified_successfully: "تم التحقق من البريد الإلكتروني بنجاح",
    phone_varified_successfully: "تم التحقق من رقم الهاتف بنجاح",
    resent_email_alert_text:
      "تم  إرسال بريد إلكتروني إلى عنوان بريدك الإلكتروني المسجل!",
    email_exist_alert_text:
      "البريد الإلكتروني مُستخدم مسبقًا! يرجى إدخال بريد آخر",
    resend: "إعادة الإرسال",
    email_address: "بريد إلكتروني",
    legal_docs_accept_tnc_text: "أوافق على الشروط والأحكام",
    terms_error: "Please select terms & conditions",
    copy_link: "نسخ الرابط",
    copied: "تم النسخ",
    refunded: "تم استرداد المبلغ",
    email_terms_text: "أرسل لي نسخة من الشروط والأحكام عبر البريد الإلكتروني",
    outofstock_notify_success: "سيتم إشعارك",
    outofstock_already_opted: "سيتم إشعارك",
    loader_message: "الرجاء الانتظار",
    banned_item_header: "غير موجود",
    banned_item_subheader: "عذرًا! المنتج الذي تبحث عنه غير متوفر مؤقتًا",
    banned_profile_subheader:
      "عذرًا! الملف الشخصي الذي تبحث عنه غير متوفر مؤقتًا",
    currency_of_preference: "العملة المفضلة",
    change_currency: "تغيير العملة",
    select_currency: "اختر العملة",
    qatari_riyal: "ريال قطري",
    dollar: "دولار أمريكي",
    language_of_preference: "اختيار اللغة",
    english: "الإنجليزية",
    arabic: "العربية",
    update_email_text: "الرجاء إدخال البريد الإلكتروني الذي تريد تحديثه",
    current_email: "البريد الإلكتروني الحالي",
    email_send_code: "إرسال الرمز",
    email_verfication_text:
      "الرجاء إدخال رمز التحقق الذي أرسلناه إلى بريدك الإلكتروني",
    verification_code_mismatch: "رمز التحقق لا يتطابق",
    email_send_code_success:
      "تم إرسال رمز التحقق (OTP) إلى البريد الإلكتروني المقدم",
    email_otp_has_been_sent_success: "تم إرسال رمز التحقق (OTP) ",
    verify_email_button: "تحقق من البريد الإلكتروني",
    form_validation_email_required: "الرجاء إدخال عنوان بريد إلكتروني صالح!",
    replace_pay: "يتوجب عليك دفع",
    replace_refund: "ستحصل على استرداد بمبلغ",
    current_status: "الوضعيّة الحالية",
    exchange_order: "الطلب المستبدل",
    exchange_product: "المنتج المستبدل",
    previous_paid: "المدفوع سابقًا",
    selected_price: "السعر المحدد",
    original_product_price: "المنتج الأساسي",
    order_details_paid: "المبلغ المدفوع",
    order_details_refund: "مبلغ الاسترداد",
    download_invoice: "تحميل الفاتورة",
    no_payment_required: "لا توجد مدفوعات مطلوبة",
    ban_account_text: "تم إيقاف حسابك مؤقتًا",
    contact_support: "اتصل بالدعم الفني",
    contact_us: "اتصل بنا",
    returns_order: "إرجاع الطلب",
    footer_my_account: "حسابي",
    support: "الدعم",
    privacy_policy: "سياسة الخصوصية",
    terms_condition: "الشروط والأحكام",
    shipment_policy: "سياسة الشحن",
    policy_center: "مركز السياسات",
    consumer_legal_return_subtext2: "اطلع على سياسة الإرجاع لدينا",
    consumer_legal_shipment_subtext2: "اطلع على سياسة الشحن لدينا",
    intellectual_property: "مطالبات الملكية الفكرية",
    faq: "الأسئلة الشائعة",
    return_policy: "سياسة الإرجاع",

    copyright_text: `© Shopez E-Commerce L.L.C - 2023. The trademarks and designs
              appearing here are exclusively owned by Shopez E-Commerce L.L.C,
              and/or its licensors, and are protected. Unauthorized use will
              invite legal action.`,
    footer_text: `Shopez is your marketplace. It’s the perfect place to go to
                declutter or discover items that will breathe new life into your
                space. Letting go has never felt so good – it’s almost as good
                as finding your new favorite thing. And we’re here to help`,
    hello_guest: "Hello Guest",
  },
});

// module.exports = LocaleStrings;
export default LocaleStrings;
