import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  renderFieldText,
  renderFieldTextarea,
  renderPhonewithFlag,
  renderFieldRadio,
  renderFieldRadio1,
  renderFieldCheckbox,
  renderDatePickerWithLabel,
  renderFieldCheckboxwithLink,
} from "../../../components/Common/base-component";
import {
  DATE_FORMAT,
  classNames,
  phoneCount,
  isValidQatarPhoneNumber,
} from "../../../components/Common/constant";
import TailwindAlert from "../../../components/Common/tailwindalert.js";
import toast, { Toaster } from "react-hot-toast";

import {
  addConsumer,
  validateEmailConsumer,
  validatePhoneConsumer,
  emailTerms,
} from "../actions";
import {
  addCart,
  fetchCart,
  validateUserCart,
  storeCart,
} from "../../Product/actions";
import { countryPhoneLength } from "../../../actions";

function Signup(props) {
  const history = useHistory();
  let {
    session,
    deviceSize,
    handleSubmit,
    localLanguage,
    phoneLengthCountry,
    getStoredCart,
  } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [checked, setChecked] = useState("");

  const onSubmitForm = (values) => {
    // console.log("values1", values);
    // debugger;
    values.name = values.first_name + " " + values.last_name;
    values.usertype = "consumer";
    if (values.phone_no) {
      values.phone = values.phone_no.replace(/[- )( +]/g, "");
    }
    values.country_id = country;

    if (values.hasOwnProperty("conf_password")) {
      values = _.omit(values, ["conf_password"]);
    }

    if (values.dob) {
      values.dob = moment(values.dob).format(DATE_FORMAT);
    }

    setLoading(true);
    debugger;
    props.validateEmailConsumer(values, (res) => {
      if (res.success == 0) {
        setLoading(false);
        toast.error(LocaleStrings.common_fail_message);
      } else if (res.success == 2) {
        setLoading(false);
        toast.error(LocaleStrings.signup_error_already_registered);
      } else {
        props.validatePhoneConsumer(values, (feedback) => {
          if (feedback.success == 0) {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
          } else if (feedback.success == 2) {
            setLoading(false);
            toast.error(LocaleStrings.signup_error_phone_already_registered);
          } else {
            props.addConsumer(values, (response) => {
              //console.log("response", response);

              if (response.success == 0) {
                setLoading(false);
                toast.error(LocaleStrings.common_fail_message);
              } else {
                if (values.emailterms) {
                  let data = {};
                  data.emailtype = "emailterm";
                  data.email = values.email;
                  props.emailTerms(data, (res) => {});
                }
                toast.success(LocaleStrings.signup_success);
                addtoCart(response.session);
              }
            });
          }
        });
      }
    });
  };

  const addtoCart = (sessionobj) => {
    //console.log("session", sessionobj);

    if (
      getStoredCart.hasOwnProperty("productid") &&
      getStoredCart.hasOwnProperty("variantid") &&
      getStoredCart.hasOwnProperty("quantity")
    ) {
      let values = {};
      props.validateUserCart(
        sessionobj,
        { variantid: getStoredCart.variantid },
        (res) => {
          if (res.success && res.valid) {
            values.productid = getStoredCart.productid;
            values.variantid = getStoredCart.variantid;
            values.quantity = getStoredCart.quantity;
            values.hasdiscount = getStoredCart.discountactive ? 1 : 0;
            if (getStoredCart.discountactive == 1) {
              values.discount = getStoredCart.discount;
            }

            props.addCart(sessionobj, values, (res) => {
              let cartVal = {};
              props.storeCart(cartVal);
              if (res.success == 0) {
                setLoading(false);
                history.push("/");
              } else {
                setLoading(false);
                props.fetchCart(sessionobj);
                history.push("/cart");
              }
            });
          } else {
            setLoading(false);
            let cartVal = {};
            props.storeCart(cartVal);
            history.push("/");
          }
        }
      );
    } else {
      setLoading(false);
      history.push("/");
    }
  };

  const phoneChange = (value, country, e, formattedValue) => {
    setPhone(value);
    setCountry(country.dialCode);
    //let pLength = phoneCount(country.format, ".");
    // if (country.countryCode == "qa") {
    //   pLength = 11;
    // }
    props.countryPhoneLength(country.dialCode);
  };

  const radioOnchange = (val) => {
    setChecked(val);
  };

  const genderOptions = [
    { text: LocaleStrings.male, value: "male" },
    { text: LocaleStrings.female, value: "female" },
    { text: LocaleStrings.rather_not_say, value: "other" },
  ];

  const languageOptions = [
    { text: LocaleStrings.arabic, value: "ar" },
    { text: LocaleStrings.english, value: "en" },
  ];

  const currencyOptions = [
    {
      text: LocaleStrings.qatari_riyal,
      value: "QAR",
    },
    { text: LocaleStrings.dollar, value: "USD" },
  ];
  console.log("getStoredCart", getStoredCart);
  return (
    <>
      <div className="bg-newarrival flex justify-center">
        <div className="bg-white w-11/12  xl:w-5/12  lg:w-6/12 md:w-8/12 mt-30 mb-30 rounded p-40">
          {showerror ? (
            <TailwindAlert alertType="error" alertMessage={alertMessage} />
          ) : (
            ""
          )}

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="text-primary font-normal text-3xl">
              {LocaleStrings.signup}
            </div>
            <div className="text-hint font-normal text-base">
              {LocaleStrings.signup_heading}
            </div>
            <div className="pt-38">
              <label
                htmlFor="email"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.email}
              </label>
              <div className="mt-1">
                <Field
                  name="email"
                  placeholder={LocaleStrings.email}
                  type="email"
                  component={renderFieldText}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="pt-3.5 grid grid-cols-2 gap-1">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-xs md:text-base text-loginLabel"
                >
                  {LocaleStrings.first_name}
                </label>
                <div className="mt-1">
                  <Field
                    name="first_name"
                    placeholder={LocaleStrings.first_name}
                    type="text"
                    component={renderFieldText}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-xs md:text-base text-loginLabel"
                >
                  {LocaleStrings.last_name}
                </label>
                <div className="mt-1">
                  <Field
                    name="last_name"
                    placeholder={LocaleStrings.last_name}
                    type="text"
                    component={renderFieldText}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            {/* <div className="pt-3.5">
              <label
                htmlFor="nick_name"
                className="block text-xs text-loginLabel"
              >
                {LocaleStrings.nick_name}
              </label>
              <div className="mt-1">
                <Field
                  name="nick_name"
                  placeholder={LocaleStrings.nick_name}
                  type="text"
                  component={renderFieldText}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div> */}
            <div className="pt-3.5">
              <label
                htmlFor="dob"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.date_of_birth}
              </label>
              <div className="mt-1 w-full">
                <Field
                  name="dob"
                  placeholder={LocaleStrings.date_of_birth}
                  type="text"
                  component={renderDatePickerWithLabel}
                  className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 text-sm md:text-base"
                />
              </div>
            </div>
            <div className="pt-3.5">
              <label
                htmlFor="phone_no"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.phone_no}
              </label>
              <div className="mt-1">
                <Field
                  name="phone_no"
                  placeholder={LocaleStrings.phone_no}
                  type="text"
                  phone={phone}
                  phoneChange={phoneChange}
                  component={renderPhonewithFlag}
                  className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 text-sm md:text-base"
                />
              </div>
            </div>
            <div className="pt-3.5">
              <label
                htmlFor="password"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.password}
              </label>
              <div className="mt-1 relative">
                <Field
                  name="new_password"
                  placeholder={LocaleStrings.password}
                  type="password"
                  show={show}
                  setShow={setShow}
                  component={renderFieldText}
                  classNameEye="absolute flex items-center text-sm top-3.5 right-3.5 cursor-pointer"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="pt-3.5">
              <label
                htmlFor="conf_password"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.confirm_password}
              </label>
              <div className="mt-1 relative">
                <Field
                  name="conf_password"
                  placeholder={LocaleStrings.confirm_password}
                  type="text"
                  component={renderFieldText}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="pt-3.5">
              <label
                htmlFor="gender"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.gender}
              </label>
              <div className="mt-1 grid grid-cols-3">
                {_.map(genderOptions, (opt, index) => {
                  return (
                    <Field
                      name="gender"
                      label={opt.text}
                      value={opt.value}
                      //checked={index == 0 ? true : false}
                      //radioOnchange={radioOnchange}
                      lebelAlign="R"
                      type="radio"
                      component={renderFieldRadio1}
                    />
                  );
                })}
              </div>
            </div>

            <div className="pt-3.5">
              <label
                htmlFor="languagechoice"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.language_of_preference}
              </label>
              <div className="mt-1 grid grid-cols-2">
                {_.map(languageOptions, (opt, index) => {
                  return (
                    <Field
                      name="languagechoice"
                      label={opt.text}
                      value={opt.value}
                      //checked={index == 0 ? true : false}
                      //radioOnchange={radioOnchange}
                      lebelAlign="R"
                      type="radio"
                      component={renderFieldRadio1}
                    />
                  );
                })}
              </div>
            </div>

            <div className="pt-3.5">
              <label
                htmlFor="currencychoice"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.currency_of_preference}
              </label>
              <div className="mt-1 grid grid-cols-2">
                {_.map(currencyOptions, (opt, index) => {
                  return (
                    <Field
                      name="currencychoice"
                      label={opt.text}
                      value={opt.value}
                      //checked={index == 0 ? true : false}
                      //radioOnchange={radioOnchange}
                      lebelAlign="R"
                      type="radio"
                      component={renderFieldRadio1}
                    />
                  );
                })}
              </div>
            </div>

            <div className="pt-3.5">
              <Field
                name="terms"
                label={LocaleStrings.legal_docs_accept_tnc_text}
                component={renderFieldCheckboxwithLink}
                type="checkbox"
                className="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                classNameLabel="text-primary text-sm font-normal hover:text-primary"
                link="/legaldocs/termsncons?origin=consumer"
              />
            </div>
            <div className="pt-3.5">
              <Field
                name="emailterms"
                label={LocaleStrings.email_terms_text}
                component={renderFieldCheckbox}
                type="checkbox"
                className="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                classNameLabel="text-primary text-sm font-normal hover:text-primary"
              />
            </div>
            <div className="pt-3.5">
              <button
                type="submit"
                className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
              >
                {loading ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  ""
                )}
                <span className="text-sm md:text-base">
                  {LocaleStrings.signup}
                </span>
              </button>
            </div>
            <div className="pt-3.5 flex justify-center">
              <a className="font-normal text-base text-primary">
                {LocaleStrings.already_account_text}
              </a>
              &nbsp;
              <a
                onClick={() => history.push("/login")}
                className="font-normal text-vendorLink text-base cursor-pointer"
              >
                {LocaleStrings.login_btn_text}
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};
  let countrycode = ownProps.phoneLengthCountry;
  let onlyPhone = "";
  if (values.phone_no) {
    values.phone_no = values.phone_no.replace(/[- )( +]/g, "");
    onlyPhone = values.phone_no.substring(ownProps.phoneLengthCountry.length);
  }

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }
  if (!values.first_name) {
    errors.first_name = LocaleStrings.form_validation_first_name_required;
  }
  if (!values.last_name) {
    errors.last_name = LocaleStrings.form_validation_last_name_required;
  }
  if (!values.phone_no) {
    errors.phone_no = LocaleStrings.form_validation_phone_no_required;
  } else if (countrycode == "974") {
    if (isValidQatarPhoneNumber("+" + values.phone_no) == false) {
      errors.phone_no = LocaleStrings.form_validation_phone_no_valid;
    }
  }
  if (!values.new_password) {
    errors.new_password = LocaleStrings.login_form_validation_password_required;
  } else if (values.new_password.length < 6) {
    errors.new_password = "minimum 6 character length";
  }
  if (!values.conf_password) {
    errors.conf_password = LocaleStrings.form_validation_conf_password_required;
  }

  if (values.new_password != values.conf_password) {
    errors.conf_password = LocaleStrings.form_validation_password_not_match;
  }

  if (!values.terms) {
    errors.terms = LocaleStrings.terms_error;
  }
  //console.log("errors", errors);
  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};
  initVals = {
    gender: "other",
    phone_no: "+974",
    languagechoice: "ar",
    currencychoice: "QAR",
  };

  return {
    session: state.session,
    deviceSize: state.deviceSize,
    localLanguage: state.localLanguage,
    initialValues: initVals,
    phoneLengthCountry: state.phoneLengthCountry,
    getStoredCart: state.getStoredCart,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addConsumer,
      validateEmailConsumer,
      validatePhoneConsumer,
      countryPhoneLength,
      emailTerms,
      addCart,
      fetchCart,
      validateUserCart,
      storeCart,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "SignupForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Signup)
);
