import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import LocaleStrings from "../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  VENDORSINGUP,
  VENDORMODE,
} from "./constant";
import Loader from "./loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import {
  XIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  InboxIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import GuestLogo from "../../assets/images/guest.png";
import NoImg from "../../assets/images/noimg.png";
import HomeIcon from "../../assets/images/home.svg";
import ProfileIcon from "../../assets/images/profile-setting.svg";
import EnglishIcon from "../../assets/images/language.svg";
import ArabicIcon from "../../assets/images/arabic.svg";
import CurrencyIcon from "../../assets/images/currency.svg";
import CategoryIcon from "../../assets/images/allcategories.svg";
import OrderIcon from "../../assets/images/myorders.svg";
import BidplaceIcon from "../../assets/images/bidplace.svg";
import NegotiationIcon from "../../assets/images/negotiation.svg";
import CouponIcon from "../../assets/images/mycoupons.svg";
import InvitationIcon from "../../assets/images/myinvitation.svg";
//import FAQIcon from "../../assets/images/faq.svg";
import LegaldocIcon from "../../assets/images/legal-docs.svg";
import SuggestionIcon from "../../assets/images/app-suggestion.svg";
import SignoutLogo from "../../assets/images/signout.svg";
import {
  isSideMenuOpen,
  languageModalOpen,
  currencyModalOpen,
} from "../../actions";
import { logout } from "../../pages/Login/actions";
import LanduangeModal from "./language";
import CurrencyModal from "./currency";
import { confirmAlert } from "react-confirm-alert";

import { profileChange } from "../../pages/Activity/actions";

function Sidebar(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    localLanguage,
    sideMenu,
    languageModal,
    localCurrency,
    currencyModal,
    user,
  } = props;

  //console.log('localLanguage',localLanguage)

  const [loading, setLoading] = useState(false);
  const navigation = [
    { name: LocaleStrings.menu_home, icon: HomeIcon, key: "/", current: true },
    {
      name: LocaleStrings.menu_profile,
      icon: ProfileIcon,
      key: "/profile",
      current: isLoggedIn,
    },
    {
      name:
        LocaleStrings.menu_language + " (" + _.startCase(localLanguage) + ")",
      icon: localLanguage == "ar" ? ArabicIcon : EnglishIcon,
      key: "language",
      current: true,
    },
    {
      name: LocaleStrings.menu_currency + " (" + localCurrency + ")",
      icon: CurrencyIcon,
      key: "currency",
      current: isLoggedIn,
    },
    {
      name: LocaleStrings.menu_categories,
      icon: CategoryIcon,
      key: "/view-category",
      current: true,
    },
    {
      name: LocaleStrings.menu_order,
      icon: OrderIcon,
      key: "/myorders",
      current: isLoggedIn,
    },
    {
      name: LocaleStrings.menu_bidplace,
      icon: BidplaceIcon,
      key: "/mybids",
      current: isLoggedIn,
    },
    {
      name: LocaleStrings.menu_negotiation,
      icon: NegotiationIcon,
      key: "/negotiation",
      current: isLoggedIn,
    },
    // {
    //   name: LocaleStrings.menu_coupon,
    //   icon: CouponIcon,
    //   key: "/mycoupons",
    //   current: isLoggedIn,
    // },
    {
      name: LocaleStrings.menu_invitation,
      icon: InvitationIcon,
      key: "/invitation",
      current: isLoggedIn,
    },
    {
      name: LocaleStrings.menu_suggestion,
      icon: SuggestionIcon,
      key: "/suggestions",
      current: isLoggedIn,
    },
    {
      name: LocaleStrings.menu_faq,
      icon: InvitationIcon,
      key: "/faq",
      current: true,
    },
    {
      name: LocaleStrings.menu_legal,
      icon: LegaldocIcon,
      key: "/legaldocs",
      current: true,
    },
  ];
  const closeSlideOvers = () => {
    props.isSideMenuOpen(false);
  };

  const signAction = () => {
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.logout_confirm_title,
        message: LocaleStrings.logout_confirm_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.logout();
              props.isSideMenuOpen(false);
              history.push("/");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    } else {
      props.isSideMenuOpen(false);
      history.push("/login");
    }
  };

  const redirectPage = (key) => {
    if (key == "language") {
      props.isSideMenuOpen(false);
      props.languageModalOpen(true);
    } else if (key == "currency") {
      props.isSideMenuOpen(false);
      props.currencyModalOpen(true);
    } else {
      props.isSideMenuOpen(false);
      history.push({ pathname: key });
    }
  };

  const redirectYou = () => {
    props.profileChange(true);
    props.isSideMenuOpen(false);
    history.push("/activity");
  };

  //console.log("currencyModal", currencyModal);
  return (
    <>
      <Transition.Root show={sideMenu} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={sideMenu}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 left-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="w-screen max-w-sidemenumobile md:max-w-sidemenu">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col overflow-y-auto scrollbar-w-1 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white focus:outline-none"
                            ></button>
                          </div>
                        </div>
                        <div className="relative flex-1 flex flex-col">
                          <div className="flex flex-col flex-grow pb-4 bg-white ">
                            {isLoggedIn ? (
                              <div className="flex items-center justify-center flex-shrink-0 px-4">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => redirectYou()}
                                >
                                  <div className="flex items-center justify-center">
                                    <img
                                      className="max-w-none h-16 w-16 rounded-full ring-2 ring-white"
                                      src={`${FILES_URL}/users/${session.userid}.jpg?api_key=${APP_API_KEY}`}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = NoImg;
                                      }}
                                    />
                                  </div>
                                  <div className="text-center pt-2 text-xl text-primary">
                                    {user.username}
                                  </div>
                                  <div className="text-center text-sm font-normal text-feedtype">
                                    {session.emailid}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="flex items-center justify-center flex-shrink-0 px-4">
                                <div>
                                  <div className="flex items-center justify-center">
                                    <img
                                      src={GuestLogo}
                                      className="rounded-full w-16 h-16 ring-2 ring-white"
                                    />
                                  </div>
                                  <div className="pt-2 text-xl text-primary">
                                    {LocaleStrings.hello_guest}
                                  </div>
                                </div>
                              </div>
                            )}

                            <nav
                              className="flex-1 px-2 bg-white space-y-1 mt-30"
                              aria-label="Sidebar"
                            >
                              {navigation.map((item) =>
                                item.current ? (
                                  item.key == "language" ||
                                  item.key == "currency" ? (
                                    <a
                                      key={item.name}
                                      onClick={() => redirectPage(item.key)}
                                      className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer"
                                    >
                                      <img
                                        src={item.icon}
                                        className={classNames(
                                          item.current
                                            ? "text-gray-500"
                                            : "text-gray-400 group-hover:text-gray-500",
                                          "mr-3 flex-shrink-0 h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span className="flex-1 text-menutext text-base">
                                        {item.name}
                                      </span>
                                    </a>
                                  ) : (
                                    <a
                                      key={item.name}
                                      href={`${item.key}`}
                                      onClick={() =>
                                        props.isSideMenuOpen(false)
                                      }
                                      className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer"
                                    >
                                      <img
                                        src={item.icon}
                                        className={classNames(
                                          item.current
                                            ? "text-gray-500"
                                            : "text-gray-400 group-hover:text-gray-500",
                                          "mr-3 flex-shrink-0 h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span className="flex-1 text-menutext text-base">
                                        {item.name}
                                      </span>
                                    </a>
                                  )
                                ) : (
                                  ""
                                )
                              )}

                              <div style={{ marginTop: 32 }}>
                                {isLoggedIn ? (
                                  ""
                                ) : (
                                  <a
                                    href={VENDORSINGUP}
                                    className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer"
                                  >
                                    <img
                                      src={SignoutLogo}
                                      className={classNames(
                                        "mr-3 flex-shrink-0 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="flex-1 text-menutext text-base">
                                      {LocaleStrings.signup_as_vendor}
                                    </span>
                                  </a>
                                )}
                              </div>

                              <div style={{ marginTop: 32 }}>
                                <a
                                  onClick={() => signAction()}
                                  className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900 cursor-pointer"
                                >
                                  <img
                                    src={SignoutLogo}
                                    className={classNames(
                                      "mr-3 flex-shrink-0 h-6 w-6"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="flex-1 text-menutext text-base">
                                    {isLoggedIn
                                      ? LocaleStrings.signout
                                      : LocaleStrings.signin}
                                  </span>
                                </a>
                              </div>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {languageModal ? <LanduangeModal /> : ""}
      {currencyModal ? <CurrencyModal /> : ""}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  sideMenu: state.sideMenu,
  languageModal: state.languageModal,
  localCurrency: state.localCurrency,
  currencyModal: state.currencyModal,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isSideMenuOpen,
      logout,
      languageModalOpen,
      currencyModalOpen,
      profileChange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
